import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
export const getIdToken = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.getSignInUserSession().getIdToken().getJwtToken();
        return token;
    }
    catch (err) {
        throw new Error('getIdToken failed: ' + err);
    }
};
export const getAuthHeader = async () => {
    return `Bearer ${await getIdToken()}`;
};
export const getAuthStatus = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    return authStatus;
};
export const signOutUser = () => {
    Auth.signOut()
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
};
export const checkUser = async () => {
    return await Auth.currentAuthenticatedUser()
        .then((user) => {
        user;
    })
        .catch((err) => (console.log(err)));
};
export const getUserId = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const userId = auth && auth.user && auth.user.userId;
    return userId;
};
export const isServiceAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group && group.includes('service') ? true : false;
};
export const isCustomerAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group && group.includes('customer') ? true : false;
};
export const isDealerAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group && group.includes('dealer') ? true : false;
};
export const isMechanicAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group && group.includes('mechanic') ? true : false;
};
export const isRootAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group && group.includes('root') ? true : false;
};
export const isInvitedUserAccount = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    return group === undefined ? true : false;
};
