import { createSlice } from '@reduxjs/toolkit';
import { getCustomer, getCustomers, getCustomersByMechanicId } from './api';
const initialState = {
    selected: {},
    current: {},
    list: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const customers = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        getCustomersStart(state) {
            state.list.loading = true;
        },
        getCustomersSuccess(state, action) {
            state.list.data = action.payload;
            state.list.loading = false;
            state.list.isLoaded = true;
        },
        getCustomersFailure(state, action) {
            state.list.loading = false;
            state.list.isLoaded = false;
            state.list.error = action.payload;
            console.log(action.payload);
        },
        getCustomersByMechanicIdStart(state) {
            state.list.loading = true;
        },
        getCustomersByMechanicIdSuccess(state, action) {
            state.list.data = action.payload;
            state.list.loading = false;
            state.list.isLoaded = true;
        },
        getCustomersByMechanicIdFailure(state, action) {
            state.list.loading = false;
            state.list.isLoaded = false;
            state.list.error = action.payload;
            console.log(action.payload);
        },
        selectCustomerSuccess(state, action) {
            state.selected = action.payload;
        },
        clearCustomerSuccess(state) {
            state.selected = {};
            state.current = {};
        },
        clearCustomersSuccess(state) {
            state.list.data = [],
                state.list.loading = false,
                state.list.isLoaded = false,
                state.list.error = '';
        },
        getCustomerStart(state) {
            state.current = {};
        },
        getCustomerSuccess(state, action) {
            state.current = action.payload;
        },
        getCustomerFailure(state) {
            state.current = 'error';
        }
    }
});
export const { getCustomersStart, getCustomersSuccess, getCustomersFailure, getCustomersByMechanicIdStart, getCustomersByMechanicIdSuccess, getCustomersByMechanicIdFailure, selectCustomerSuccess, clearCustomerSuccess, clearCustomersSuccess, getCustomerStart, getCustomerSuccess, getCustomerFailure } = customers.actions;
export default customers.reducer;
export const fetchCustomers = () => async (dispatch) => {
    try {
        dispatch(getCustomersStart());
        const customersList = await getCustomers();
        dispatch(getCustomersSuccess(customersList));
    }
    catch (err) {
        dispatch(getCustomersFailure(err && err.message));
    }
};
export const fetchCustomer = (customerId) => async (dispatch) => {
    try {
        dispatch(getCustomerStart());
        const customer = await getCustomer(customerId);
        dispatch(getCustomerSuccess(customer));
    }
    catch (err) {
        dispatch(getCustomerFailure(err && err.message));
    }
};
export const fetchCustomersByMechanicId = (mechanicId) => async (dispatch) => {
    try {
        dispatch(getCustomersByMechanicIdStart());
        const customersList = await getCustomersByMechanicId(mechanicId);
        dispatch(getCustomersByMechanicIdSuccess(customersList));
    }
    catch (err) {
        dispatch(getCustomersByMechanicIdFailure(err && err.message));
    }
};
