import { tabsArray } from 'constants/widgets';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const WidgetsRedirect = () => {
    const list = tabsArray;
    const history = useHistory();
    useEffect(() => {
        list && list[0] && history.push(`/widgets/${list[0].uuid}`);
    }, [history.location.pathname]);
    return null;
};
export default WidgetsRedirect;
