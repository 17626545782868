import React from 'react';
import { getSidebarWidth } from '@utils/sidebar';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import BreadCrumbs from './Header/BreadCrumbs';
const SubHeader = () => {
    const auth = useSelector((state) => state.auth && state.auth);
    const group = auth &&
        auth.user &&
        auth.user.userGroups;
    const groupNames = group && group.join(', ');
    return auth && auth.success ? (React.createElement("div", { id: 'sub-header', className: isMobile ? 'sub-header-mobile' : '', style: {
            width: isMobile ? '100vw' : auth && !auth.success ? '100vw' : `calc(100vw - ${getSidebarWidth()}px)`,
        } },
        isMobile && React.createElement(BreadCrumbs, null),
        process.env.ENV === 'development' && !isMobile &&
            React.createElement("div", { style: {
                    lineHeight: '42px',
                    textAlign: 'right',
                    fontSize: '10px',
                    paddingRight: '20px',
                    textTransform: 'capitalize'
                } },
                React.createElement("i", null,
                    "Account type: ",
                    groupNames,
                    " (Development Only)")))) : null;
};
export default SubHeader;
