export const getDutchAmountFromCents = (Euro) => {
    if (isNaN(Euro)) {
        return 'NaN';
    }
    else {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
        }).format(Euro / 100);
    }
};
export const isNumber = (value) => {
    return typeof value === 'number';
};
export const getDutchNumber = (number) => {
    if (isNaN(number)) {
        return '---';
    }
    else {
        return new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }
};
export const getDutchNumberWithDecimals = (number, decimals) => {
    if (isNaN(number)) {
        return '---';
    }
    else {
        return new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }).format(number);
    }
};
