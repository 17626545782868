import parse from 'date-fns/parse';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';
export const formatToDutchDateAndTime = (timestamp) => {
    const dateFormat = timestamp && format(new Date(timestamp), 'yyyy-MM-dd / HH:mm:ss', {
        locale: nl,
    });
    return dateFormat ? dateFormat : null;
};
export const convertDateFormat = ({ initialValue = '', from = 'dd-MM-yyyy', to = 'yyyy-MM-dd' }) => {
    if (!initialValue)
        return '';
    return format(parse(initialValue, from, new Date()), to);
};
export const dateWithoutTimezone = (date) => {
    const tzoffset = date.getTimezoneOffset() * 60000;
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
        .toISOString()
        .slice(0, -1);
    return withoutTimezone;
};
