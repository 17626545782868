import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconChevron from '!svg-react-loader?name=IconChevron!assets/svg/icon-chevron-left2.svg';
import { orderBy } from 'lodash/fp';
const SubMenuCustomScreens = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const customScreens = useSelector((state) => state.customScreens && state.customScreens && state.customScreens.details);
    const screensSorted = orderBy([(screen) => screen.Name.toLowerCase()], 'asc', customScreens);
    const onClickLink = (url) => {
        history.push(url);
    };
    return (React.createElement("div", { className: 'sub-menu' },
        React.createElement("div", { className: 'sub-menu-header font-size-11' }, t('main.customScreens')),
        React.createElement("div", { className: 'locations-menu' }, screensSorted &&
            screensSorted.map((item, key) => {
                const screens2Sorted = orderBy([(screen) => screen.Name.toLowerCase()], 'asc', item.Screens);
                const numberOfScreens = item.Screens.length;
                return (React.createElement("div", { className: `item wrap-${key}`, key: key },
                    React.createElement("input", { type: 'radio', id: `tab-${key}`, name: 'tabs' }),
                    React.createElement("label", { htmlFor: `tab-${key}` },
                        React.createElement("p", null, item.Name),
                        React.createElement("div", { className: 'chevron' },
                            React.createElement(IconChevron, null))),
                    numberOfScreens > 0 && (React.createElement("div", { className: 'item-submenu' }, screens2Sorted.map((item2, key2) => {
                        return (React.createElement("div", { className: 'sub-item wrap', key: key2 },
                            React.createElement("input", { type: 'radio', id: `sub-item-${key}-${key2}`, name: 'sub-item', onClick: () => onClickLink(`/custom-screens/${item2.GroupId}/${item2.Id}`) }),
                            React.createElement("label", { htmlFor: `sub-item-${key}-${key2}` },
                                React.createElement("p", null, item2.Name))));
                    })))));
            }))));
};
export default SubMenuCustomScreens;
