import React, { useEffect, useState } from 'react';
import Menu from './Sidebar/Menu';
import UserProfile from './Sidebar/UserProfile';
import { getSidebarWidth } from '@utils/sidebar';
import SubMenuLocations from './Sidebar/SubMenuLocations';
import SubMenuAdmin from './Sidebar/SubMenuAdmin';
import { useHistory } from 'react-router-dom';
import SubMenuCustomScreens from './Sidebar/SubMenuCustomScreens';
const Sidebar = (props) => {
    const history = useHistory();
    const [pagePath, setPagePath] = useState('');
    const { onClickSidebarBtn, sidebar } = props;
    useEffect(() => {
        setPagePath(location.pathname);
        history.listen((location) => {
            setPagePath(location.pathname);
        });
    }, [history]);
    return (React.createElement("div", { id: 'sidebar', style: {
            width: `${getSidebarWidth()}px`,
        } },
        React.createElement("div", { className: 'sidebar-main-menu' },
            React.createElement(Menu, { onClickSidebarBtn: onClickSidebarBtn })),
        React.createElement(UserProfile, { pagePath: pagePath, sidebar: sidebar }),
        pagePath.includes('locations') && sidebar === 'open' ? (React.createElement(SubMenuLocations, null)) : pagePath.includes('custom-screens') && sidebar === 'open' ? (React.createElement(SubMenuCustomScreens, null)) :
            pagePath.includes('admin') && sidebar === 'open' && React.createElement(SubMenuAdmin, null)));
};
export default Sidebar;
