import Cookies from 'universal-cookie';
export const textToCamelCase = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, '');
};
export const getThemeClassName = () => {
    const cookies = new Cookies();
    return `${cookies.get('theme')}-theme`;
};
