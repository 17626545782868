import { createSlice } from '@reduxjs/toolkit';
import { getDealerInvites, getMechanicsByDealerId } from './api';
const initialState = {
    invites: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    },
    mechanics: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const dealers = createSlice({
    name: 'dealers',
    initialState,
    reducers: {
        getDealerInvitesStart(state) {
            state.invites.loading = true;
        },
        getDealerInvitesSuccess(state, action) {
            state.invites.data = action.payload;
            state.invites.loading = false;
            state.invites.isLoaded = true;
        },
        getDealerInvitesFailure(state, action) {
            state.invites.loading = false;
            state.invites.isLoaded = false;
            state.invites.error = action.payload;
            console.log(action.payload);
        },
        clearDealerInvitesSuccess(state) {
            state.invites.data = [],
                state.invites.loading = false,
                state.invites.isLoaded = false,
                state.invites.error = '';
        },
        getMechanicsByDealerIdStart(state) {
            state.mechanics.loading = true;
        },
        getMechanicsByDealerIdSuccess(state, action) {
            state.mechanics.data = action.payload;
            state.mechanics.loading = false;
            state.mechanics.isLoaded = true;
        },
        getMechanicsByDealerIdFailure(state, action) {
            state.mechanics.loading = false;
            state.mechanics.isLoaded = false;
            state.mechanics.error = action.payload;
            console.log(action.payload);
        },
        clearMechanicsByDealerIdSuccess(state) {
            state.mechanics.data = [],
                state.mechanics.loading = false,
                state.mechanics.isLoaded = false,
                state.mechanics.error = '';
        }
    }
});
export const { getDealerInvitesStart, getDealerInvitesSuccess, getDealerInvitesFailure, clearDealerInvitesSuccess, getMechanicsByDealerIdStart, getMechanicsByDealerIdSuccess, getMechanicsByDealerIdFailure, clearMechanicsByDealerIdSuccess } = dealers.actions;
export default dealers.reducer;
export const fetchDealerInvites = (customerId) => async (dispatch) => {
    try {
        dispatch(getDealerInvitesStart());
        const DealerInvitesList = await getDealerInvites(customerId);
        dispatch(getDealerInvitesSuccess(DealerInvitesList));
    }
    catch (err) {
        dispatch(getDealerInvitesFailure(err && err.message));
    }
};
export const fetchMechanicsByDealerId = (dealerId) => async (dispatch) => {
    try {
        dispatch(getMechanicsByDealerIdStart());
        const mechnicsList = await getMechanicsByDealerId(dealerId);
        dispatch(getMechanicsByDealerIdSuccess(mechnicsList));
    }
    catch (err) {
        dispatch(getMechanicsByDealerIdFailure(err && err.message));
    }
};
