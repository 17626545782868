import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from 'semantic-ui-react';
import { change, reset } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Input from '@formComponents/Input';
import IconAdd from '!svg-react-loader?name=IconAdd!assets/svg/icon-add.svg';
import TabsList from './EditModeTabs/TabsList';
import TabsListItem from './EditModeTabs/TabsListItem';
import uuid from 'react-uuid';
import slugify from 'slugify';
import { storeTabsSuccess } from 'app/widgets/state/slice';
import IconClose from '!svg-react-loader?name=IconClose!assets/svg/icon-close.svg';
const EditModeTabs = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { visible, setVisible } = props;
    const formValues = useSelector((state) => state.form.EditModeSidebarForm && state.form.EditModeSidebarForm.values);
    const tabs = useSelector((state) => state && state.widgets && state.widgets.tabs);
    const [tabItems, setTabItems] = useState([]);
    useEffect(() => {
        tabs && tabs.length > 0 && setTabItems(tabs);
    }, [tabs, visible]);
    const onCloseSideBar = () => {
        setVisible(false);
        dispatch(reset('EditModeSidebarForm'));
    };
    const onAddNew = () => {
        const newTab = {
            index: tabItems.length,
            uuid: uuid(),
            name: formValues && formValues.addNewTab,
            friendlyName: slugify(formValues && formValues.addNewTab, { lower: true })
        };
        const newArray = tabItems.concat(newTab);
        setTabItems(newArray);
        dispatch(change('EditModeSidebarForm', 'addNewTab', ''));
    };
    const onSaveEditMode = () => {
        const newArray = tabItems.map((obj, key) => {
            return {
                index: key,
                uuid: obj.uuid,
                color: formValues[`${obj.uuid}-color`] ? formValues[`${obj.uuid}-color`] : '008072',
                name: formValues[obj.uuid],
                friendlyName: slugify(formValues[obj.uuid], { lower: true })
            };
        });
        dispatch(storeTabsSuccess(newArray));
        setVisible(false);
        dispatch(reset('EditModeSidebarForm'));
    };
    const onDeleteTab = (uuid) => {
        const newArray = tabItems.filter((obj) => {
            return obj.uuid !== uuid;
        });
        setTabItems(newArray);
    };
    const dragEnded = (param) => {
        const { source, destination } = param;
        const _arr = [...tabItems];
        const _item = _arr.splice(source.index, 1)[0];
        _arr.splice(destination.index, 0, _item);
        setTabItems(_arr);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'close-sidebar-icon', onClick: () => onCloseSideBar() },
            React.createElement(IconClose, null)),
        React.createElement(Input, { fluid: true, name: 'addNewTab', label: t('widgets.newTab'), action: {
                btnDisabled: (formValues && formValues.addNewTab === undefined || formValues && formValues.addNewTab && formValues.addNewTab.length <= 2),
                content: React.createElement(IconAdd, null),
                onClick: () => onAddNew()
            } }),
        React.createElement(Divider, { section: true }),
        React.createElement("b", { className: 'label' }, t('widgets.edit')),
        React.createElement(DragDropContext, { onDragEnd: dragEnded },
            React.createElement(Droppable, { droppableId: 'tabItems-wrapper' }, (provided) => (React.createElement(TabsList, { ref: provided.innerRef, ...provided.droppableProps },
                tabItems && tabItems.length > 0 && tabItems.map((item, index) => {
                    return (React.createElement(Draggable, { draggableId: `item-${item.uuid}`, index: index, key: item.uuid }, (_provided, _snapshot) => (React.createElement(TabsListItem, { visible: visible, formName: 'EditModeSidebarForm', ref: _provided.innerRef, dragHandleProps: _provided.dragHandleProps, ..._provided.draggableProps, snapshot: _snapshot, item: item, onDeleteTab: onDeleteTab, tabItems: tabItems }))));
                }),
                provided.placeholder)))),
        React.createElement(Button, { floated: 'right', className: 'theme-btn', content: t('main.save'), onClick: () => onSaveEditMode() }),
        React.createElement(Button, { floated: 'right', className: 'text-btn', content: t('main.cancel'), onClick: () => onCloseSideBar() })));
};
export default EditModeTabs;
