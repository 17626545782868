import api from '@state/api';
const userEndPoint = `${process.env.USER_API}/userapi/v1`;
const stateEndPoint = `${process.env.STATE_API}/stateapi/v1`;
const rootEndPoint = `${process.env.ROOT_API}/webservice/`;
const dataEndPoint = `${process.env.DATA_API}/dataapi/v1`;
export const getDevice = async (deviceId) => {
    const { data } = await api.get(`${userEndPoint}/devices/${deviceId}`);
    return data;
};
export const getDeviceMenu = async (hardware, version, deviceId) => {
    const { data } = await api.get(`${rootEndPoint}/config.svc/dispositions?hardware=${hardware}&version=${version}&did=${deviceId}`);
    return data;
};
export const getDeviceState = async (deviceId) => {
    const { data } = await api.get(`${stateEndPoint}/valuerecords/${deviceId}/full`);
    return data;
};
export const getDeviceScreen = async (deviceId) => {
    const result = await api.get(`${rootEndPoint}/screen?id=${deviceId}&dids=`);
    return result;
};
export const getDeviceBackups = async (deviceId) => {
    const result = await api.get(`${dataEndPoint}/backup/${deviceId}`);
    return result;
};
export const createDeviceBackup = async (deviceId) => {
    const { data } = await api.post(`${dataEndPoint}/backup`, deviceId);
    console.log('data=', data);
    return data;
};
