import { createSlice } from '@reduxjs/toolkit';
import { getUser } from './api';
const initialState = {
    user: {},
    signupComplete: []
};
const users = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUserSuccess(state, action) {
            state.user = action.payload;
        },
        getUserFailure(_state, action) {
            console.log(action.payload);
        },
        getUserSignupCompleteSuccess(state, action) {
            const oldArray = state && state.signupComplete;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.signupComplete = newArray;
        }
    }
});
export const { getUserSuccess, getUserFailure, getUserSignupCompleteSuccess } = users.actions;
export default users.reducer;
export const fetchUser = (userId) => async (dispatch) => {
    try {
        const user = await getUser(userId);
        dispatch(getUserSuccess(user));
    }
    catch (err) {
        dispatch(getUserFailure(err && err.message));
    }
};
