import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconChevron from '!svg-react-loader?name=IconChevron!assets/svg/icon-chevron-left2.svg';
import IconCloudOffline from '!svg-react-loader?name=IconCloudOffline!assets/svg/icon-cloud-offline.svg';
import { orderBy } from 'lodash/fp';
import { Link } from 'react-router-dom';
import IconLock from '!svg-react-loader?name=IconLock!assets/svg/icon-lock.svg';
export const onClickItem = (id) => {
    const navElement = document.getElementById('nav');
    const listElement = document.getElementById(id);
    if (listElement && listElement.classList && listElement.classList.contains('active')) {
        listElement.classList.remove('active');
    }
    else {
        const elementItems = navElement && navElement.querySelectorAll('li.active');
        elementItems && elementItems.forEach((item) => {
            item.classList.remove('active');
        });
        listElement && listElement.classList && listElement.classList.add('active');
    }
};
export const onClickSubItem = (navId, id) => {
    const subNavElement = document.getElementById(`sub-nav-${navId}`);
    const subListElement = document.getElementById(id);
    if (subListElement && subListElement.classList && subListElement.classList.contains('active')) {
    }
    else {
        const subElementItems = subNavElement && subNavElement.querySelectorAll('li.active');
        subElementItems && subElementItems.forEach((item) => {
            item.classList.remove('active');
        });
        subListElement && subListElement.classList && subListElement.classList.add('active');
    }
};
const LocationsSubMenu = () => {
    const { t } = useTranslation();
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const locationsSorted = orderBy([(gateway) => gateway.name.toLowerCase()], 'asc', locations);
    useEffect(() => {
        const pagePath = location.pathname;
        const pathArray = pagePath.split('/');
        const pathArrayWithoutEmpty = pathArray.filter((elm) => elm);
        if (pathArrayWithoutEmpty[0] === 'locations' && pathArrayWithoutEmpty[1]) {
            const locationId = pathArrayWithoutEmpty[1];
            onClickItem(locationId);
            if (pathArrayWithoutEmpty[2]) {
                const deviceId = pathArrayWithoutEmpty[2];
                onClickSubItem(locationId, deviceId);
            }
        }
    }, [history]);
    return (React.createElement("div", { className: 'sub-menu' },
        React.createElement("div", { className: 'sub-menu-header' }, t('main.locations')),
        React.createElement("div", { className: 'menu-with-submenu' },
            React.createElement("ul", { id: "nav" }, locationsSorted &&
                locationsSorted.map((item, key) => {
                    const devicesSorted = orderBy([(device) => device.name.toLowerCase()], 'asc', item.devices);
                    const numberOfDevices = item.devices.length;
                    return (React.createElement("li", { key: key, id: item.id },
                        React.createElement(Link, { to: `/locations/${item.id}`, onClick: () => onClickItem(item.id), className: 'parent-item' },
                            React.createElement("p", null,
                                item && item.online === false && (React.createElement("span", { className: 'offline' },
                                    React.createElement(IconCloudOffline, null))),
                                item.name),
                            React.createElement("div", { className: 'chevron' },
                                React.createElement(IconChevron, null))),
                        React.createElement("ul", { id: `sub-nav-${item.id}`, className: 'content' }, numberOfDevices > 0 && devicesSorted.map((item2, key2) => {
                            return (React.createElement("li", { key: key2, id: item2.id },
                                React.createElement(Link, { to: `/locations/${item2.gatewayId}/${item2.id}`, className: 'sub-item', onClick: () => onClickSubItem(item.id, item2.id) },
                                    React.createElement("p", null,
                                        item2 && item2.online === false && (React.createElement("span", { className: 'offline' },
                                            React.createElement(IconCloudOffline, null))),
                                        item2 && item2.isLicenced === false && (React.createElement("span", { className: 'lock' },
                                            React.createElement(IconLock, null))),
                                        item2.name))));
                        }))));
                })))));
};
export default LocationsSubMenu;
