import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDeviceById } from '@utils/devices';
import { useTranslation } from 'react-i18next';
import { StienenMessage } from '@elementComponents/Messages';
import IconLockShield from '!svg-react-loader?name=IconLockShield!assets/svg/icon-lock-shield.svg';
import { MenuItem, Tab, TabPane } from 'semantic-ui-react';
import { fetchDeviceMenu } from 'app/device/state/slice';
import { useDispatch, useSelector } from 'react-redux';
import IconEye from '!svg-react-loader?name=IconEye!assets/svg/icon-eye.svg';
import IconDevice from '!svg-react-loader?name=IconDevice!assets/svg/icon-device.svg';
import IconCloudOnline from '!svg-react-loader?name=IconCloudOnline!assets/svg/icon-cloud-online.svg';
import IconStatistics from '!svg-react-loader?name=IconStatistics!assets/svg/icon-statistics.svg';
import IconBook from '!svg-react-loader?name=IconBook!assets/svg/icon-book.svg';
import TabRemoteFlutter from './components/TabRemoteFlutter';
import TabLogs from './components/TabLogs';
const Device = () => {
    const { t } = useTranslation();
    const { locationId, deviceId, tabType } = useParams();
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const device = getDeviceById(deviceId, locations);
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(1);
    useEffect(() => {
        device && device.hardware && device.version && deviceId && dispatch(fetchDeviceMenu(device.hardware, device.version, deviceId));
    }, [deviceId]);
    useEffect(() => {
        tabType === 'remote' ? setActiveIndex(0)
            : tabType === 'device' ? setActiveIndex(1)
                : tabType === 'status' ? setActiveIndex(2)
                    : tabType === 'statistics' ? setActiveIndex(3)
                        : tabType === 'logs' ? setActiveIndex(4)
                            : !tabType && setActiveIndex(0);
    }, [tabType, deviceId]);
    const handleTabChange = (index) => {
        setActiveIndex(index);
    };
    const panes = [
        {
            menuItem: (React.createElement(MenuItem, { key: 0, active: activeIndex === 0, onClick: () => handleTabChange(0), as: Link, to: `/locations/${locationId}/${deviceId}/remote` },
                React.createElement("span", null,
                    React.createElement(IconEye, null)),
                React.createElement("h3", null, t('devices.remote')))),
            render: () => React.createElement(TabRemoteFlutter, { device: device }),
        },
        {
            menuItem: (React.createElement(MenuItem, { key: 1, active: activeIndex === 1, onClick: () => handleTabChange(1), as: Link, to: `/locations/${locationId}/${deviceId}/device` },
                React.createElement("span", null,
                    React.createElement(IconDevice, null)),
                React.createElement("h3", null, t('devices.device')))),
            render: () => React.createElement(TabPane, null, "Tab 1 Content"),
        },
        {
            menuItem: (React.createElement(MenuItem, { key: 2, active: activeIndex === 2, onClick: () => handleTabChange(2), as: Link, to: `/locations/${locationId}/${deviceId}/status` },
                React.createElement("span", null,
                    React.createElement(IconCloudOnline, null)),
                React.createElement("h3", null, t('devices.status')))),
            render: () => React.createElement(TabPane, null, "Tab 2 Content"),
        },
        {
            menuItem: (React.createElement(MenuItem, { key: 3, active: activeIndex === 3, onClick: () => handleTabChange(3), as: Link, to: `/locations/${locationId}/${deviceId}/statistics` },
                React.createElement("span", null,
                    React.createElement(IconStatistics, null)),
                React.createElement("h3", null, t('devices.statistics')))),
            render: () => React.createElement(TabPane, null, "Tab 3 Content"),
        },
        {
            menuItem: (React.createElement(MenuItem, { key: 4, active: activeIndex === 4, onClick: () => handleTabChange(4), as: Link, to: `/locations/${locationId}/${deviceId}/logs` },
                React.createElement("span", null,
                    React.createElement(IconBook, null)),
                React.createElement("h3", null, t('devices.logs')))),
            render: () => React.createElement(TabLogs, { device: device }),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, device && device.name))),
        device && device.isLicenced === false && (React.createElement(StienenMessage, { svgIcon: React.createElement(IconLockShield, null), type: 'info', header: t('devices.notLicensedMessageHeader'), description: t('devices.notLicensedMessageDescription'), className: 'mb3' })),
        React.createElement("div", { className: 'stienen-tabs' },
            React.createElement("div", { className: 'header-border' }),
            React.createElement(Tab, { activeIndex: activeIndex, panes: panes }))));
};
export default Device;
