import React, { useState } from 'react';
import { Field, change } from 'redux-form';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import nl from 'date-fns/locale/nl';
import format from 'date-fns/format';
import { endOfDay, isToday, parseISO, startOfDay } from 'date-fns';
import { convertDateFormat, dateWithoutTimezone } from '@utils/dates';
import IconCalendar from '!svg-react-loader?name=IconCalendar!assets/svg/icon-calendar.svg';
const Component = (props) => {
    const { datePickerOnly, required, maxDate, selectsRange, formName, input, meta, label, showToday, ...rest } = props;
    const dispatch = useDispatch();
    const formValues = useSelector((state) => state.form[formName] && state.form[formName].values);
    const [startDate, setStartDate] = useState(() => {
        if (formValues?.StartDate) {
            const converted = convertDateFormat({ initialValue: formValues.StartDate, from: 'dd-MM-yyyy', to: 'yyyy-MM-dd' });
            return parseISO(converted);
        }
    });
    const [endDate, setEndDate] = useState(() => {
        if (formValues?.EndDate) {
            const converted = convertDateFormat({ initialValue: formValues.EndDate, from: 'dd-MM-yyyy', to: 'yyyy-MM-dd' });
            return parseISO(converted);
        }
    });
    const onChangeRange = (dates) => {
        const start = dates[0] && startOfDay(dates[0]);
        const end = dates[1] && isToday(dates[1]) ? new Date() : dates[1] && endOfDay(dates[1]);
        start && end
            ? dispatch(change(formName, 'RangeIsSelected', true))
            : dispatch(change(formName, 'RangeIsSelected', false));
        setStartDate(start);
        setEndDate(end);
        start && (dispatch(change(formName, 'StartDate', format(start, 'dd-MM-yyyy'))),
            dispatch(change(formName, 'StartDateISO', dateWithoutTimezone(new Date(start)))));
        end && (dispatch(change(formName, 'EndDate', format(end, 'dd-MM-yyyy'))),
            dispatch(change(formName, 'EndDateISO', dateWithoutTimezone(new Date(end)))));
        dispatch(change(formName, input.name, `${start && format(start, 'dd-MM-yyyy')} t/m ${end && format(end, 'dd-MM-yyyy')}`));
    };
    return (React.createElement("div", { className: `date-picker-input ${selectsRange ? 'range' : ''}` },
        label && React.createElement("label", null, label),
        React.createElement(DatePicker, { withPortal: true, peekNextMonth: true, showMonthDropdown: true, showYearDropdown: true, maxDate: maxDate, selectsRange: selectsRange, datePickerOnly: datePickerOnly, showToday: showToday, dropdownMode: 'select', required: required, value: input.value, locale: nl, dateFormat: 'dd-MM-yyyy', selected: startDate, startDate: startDate, endDate: endDate, isClearable: false, onChange: (date) => {
                onChangeRange(date);
            }, ...rest, error: meta.touched && meta.error ? true : false, helperText: meta.touched && meta.error ? meta.error : null }),
        React.createElement("div", { className: 'calendar-icon' },
            React.createElement(IconCalendar, null))));
};
export const ReduxDatePicker = (props) => React.createElement(Field, { component: Component, ...props });
export default React.memo(ReduxDatePicker);
