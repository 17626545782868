import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const UserProfile = (props) => {
    const { sidebar, pagePath } = props;
    const user = useSelector((state) => state.auth && state.auth.user);
    const emailAddress = user && user.email;
    const emailAddressWithoutDomain = emailAddress.split('@')[0];
    const emailAddressWithDots = emailAddressWithoutDomain.split('.');
    const firstLetter = emailAddressWithDots[0] && emailAddressWithDots[0].charAt(0);
    const secondLetter = emailAddressWithDots[1] && emailAddressWithDots[1].charAt(0);
    return (pagePath.includes('locations')
        || pagePath.includes('admin')
        || pagePath.includes('custom-screens')) && sidebar === 'open' ? (React.createElement(React.Fragment, null,
        React.createElement(Link, { to: '/settings', className: 'user-profile' },
            React.createElement("p", { className: 'user-name' }, emailAddress ? emailAddress : ''),
            React.createElement("div", { className: 'user-image user-characters' },
                React.createElement("h1", null,
                    firstLetter && React.createElement("span", { className: 'first' }, firstLetter),
                    secondLetter && React.createElement("span", { className: 'second' }, secondLetter)))),
        React.createElement("div", { className: 'user-profile-sub-header' }))) : (React.createElement(Link, { to: '/settings', className: 'user-profile-sidebar-closed' },
        React.createElement("p", { className: 'user-name' }, emailAddress ? emailAddress : ''),
        React.createElement("div", { className: 'user-image user-characters' },
            React.createElement("h1", null,
                firstLetter && React.createElement("span", { className: 'first' }, firstLetter),
                secondLetter && React.createElement("span", { className: 'second' }, secondLetter)))));
};
export default UserProfile;
