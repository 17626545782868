import React from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { TableHeaderBorder } from '@elementComponents/TableHeaderBorder';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCookieLocale } from '@utils/locales';
import { fetchAlarmReference } from 'app/reference/state/slice';
import { getAlarmTranslation } from '@utils/reference';
const AllAlarms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const alarmReference = useSelector((state) => state.reference && state.reference.alarm);
    return (React.createElement(Table, { selectable: true, className: `stienen-table sticky-header fixed-last-column mb2 ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
        React.createElement(TableHeader, null,
            React.createElement(TableHeaderBorder, { negative: true }),
            React.createElement(TableRow, null,
                React.createElement(TableHeaderCell, null),
                React.createElement(TableHeaderCell, null, t('alarms.location')),
                React.createElement(TableHeaderCell, null, t('alarms.device')),
                React.createElement(TableHeaderCell, null, t('alarms.alarm')),
                React.createElement(TableHeaderCell, null, t('alarms.type')),
                React.createElement(TableHeaderCell, { style: { width: '130px' } }))),
        React.createElement(TableBody, null, alarms && alarms.length > 0
            ? alarms.map((alarm, key) => {
                const findLocation = locations.find((location) => location.id === alarm.gid);
                const findDevice = findLocation && findLocation.devices.find((device) => device.id === alarm.did);
                const hardware = findDevice.hardware;
                const version = findDevice.version;
                const language = getCookieLocale().code;
                const findReference = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language);
                if (hardware && version && language && !findReference) {
                    dispatch(fetchAlarmReference(hardware, version, 'nl'));
                }
                return (React.createElement(TableRow, { key: key, className: 'show-btn-on-hover', style: { height: '60px' } },
                    React.createElement(TableCell, null,
                        "#",
                        key + 1),
                    React.createElement(TableCell, null, alarm.gatewayName),
                    React.createElement(TableCell, null, alarm.deviceName),
                    React.createElement(TableCell, null, getAlarmTranslation(hardware, version, 'nl', alarm.alarmCode, alarmReference)),
                    React.createElement(TableCell, null, alarm.controlType),
                    React.createElement(TableCell, null,
                        React.createElement(Button, { className: 'theme-btn button-inside-table', content: t('main.goTo'), size: 'small', as: Link, to: `/locations/${alarm.gid}/${alarm.did}` }))));
            }) : (React.createElement(TableRow, { style: { height: '60px' } },
            React.createElement(TableCell, { colSpan: 6, textAlign: 'center' }, t('alarms.noAlarmsAvailable')))))));
};
export default AllAlarms;
