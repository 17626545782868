import React, { useEffect } from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { TableHeaderBorder } from '@elementComponents/TableHeaderBorder';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchDeviceBackups } from 'app/device/state/slice';
import CreateBackup from './CreateBackup';
const Backups = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = useParams();
    const deviceBackups = useSelector((state) => state.device && state.device.backups && state.device.backups.data);
    const deviceBackupsLoading = useSelector((state) => state.device && state.device.backups && state.device.backups.loading);
    console.log('deviceBackups=', deviceBackups);
    useEffect(() => {
        deviceId && dispatch(fetchDeviceBackups(deviceId));
    }, [deviceId]);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('locations.backups'))),
            React.createElement("div", { className: 'justify-content-end align-items-center' },
                React.createElement(CreateBackup, { deviceId: deviceId }))),
        React.createElement(Table, { selectable: true, className: `stienen-table sticky-header ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
            React.createElement(TableHeader, null,
                React.createElement(TableHeaderBorder, null),
                React.createElement(TableRow, null,
                    React.createElement(TableHeaderCell, null, t('locations.date')),
                    React.createElement(TableHeaderCell, null))),
            React.createElement(TableBody, null, deviceBackupsLoading ?
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 2, textAlign: 'center' },
                        React.createElement(Icon, { loading: true, name: 'spinner' })))
                : deviceBackups && deviceBackups.length > 0
                    ? deviceBackups.map((backup, key) => {
                        return (React.createElement(TableRow, { key: key, className: 'show-btn-on-hover' },
                            React.createElement(TableCell, null, backup.date),
                            React.createElement(TableCell, null)));
                    }) : (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 2, textAlign: 'center' }, t('locations.noBackupsAvailable'))))))));
};
export default Backups;
