import React, { useRef } from 'react';
import IconClose from '!svg-react-loader?name=IconClose!assets/svg/icon-close.svg';
import LogoGreenAndWhite from '!svg-react-loader?name=LogoGreenAndWhite!assets/svg/logo-farm-connect-green-and-white.svg';
import { Link, NavLink } from 'react-router-dom';
import IconHome from '!svg-react-loader?name=IconHome!assets/svg/icon-home.svg';
import IconAdmin from '!svg-react-loader?name=IconAdmin!assets/svg/icon-admin.svg';
import IconWidgets from '!svg-react-loader?name=IconWidgets!assets/svg/icon-widget.svg';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import IconMonitoring from '!svg-react-loader?name=IconMonitoring!assets/svg/icon-monitoring.svg';
import { useSelector } from 'react-redux';
import IconAlarms from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
import { isCustomerAccount, isServiceAccount } from '@utils/auth';
import { useTranslation } from 'react-i18next';
import IconChevron from '!svg-react-loader?name=IconChevron!assets/svg/icon-chevron-left2.svg';
import IconCloudOffline from '!svg-react-loader?name=IconCloudOffline!assets/svg/icon-cloud-offline.svg';
import IconSettings from '!svg-react-loader?name=IconSettings!assets/svg/icon-cog.svg';
import LogoutButton from '../Header/LogoutButton';
export const onClickItem = (id) => {
    const navElement = document.getElementById('nav');
    const listElement = document.getElementById(id);
    if (listElement && listElement.classList && listElement.classList.contains('active')) {
        listElement.classList.remove('active');
    }
    else {
        const elementItems = navElement && navElement.querySelectorAll('li.active');
        elementItems && elementItems.forEach((item) => {
            item.classList.remove('active');
        });
        listElement && listElement.classList && listElement.classList.add('active');
    }
};
export const onClickSubItem = (navId, id) => {
    const subNavElement = document.getElementById(`sub-nav-${navId}`);
    const subListElement = document.getElementById(id);
    if (subListElement && subListElement.classList && subListElement.classList.contains('active')) {
    }
    else {
        const subElementItems = subNavElement && subNavElement.querySelectorAll('li.active');
        subElementItems && subElementItems.forEach((item) => {
            item.classList.remove('active');
        });
        subListElement && subListElement.classList && subListElement.classList.add('active');
    }
};
const MainMenuMobile = (props) => {
    const { t } = useTranslation();
    const customerAccount = isCustomerAccount();
    const serviceAccount = isServiceAccount();
    const isCustomer = isCustomerAccount();
    const sideBarRef = useRef(null);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const alarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const widgetTabs = useSelector((state) => state && state.widgets && state.widgets.tabs);
    const user = useSelector((state) => state.auth && state.auth.user);
    const emailAddress = user && user.email;
    const emailAddressWithoutDomain = emailAddress && emailAddress.split('@')[0];
    const emailAddressWithDots = emailAddressWithoutDomain && emailAddressWithoutDomain.split('.');
    const firstLetter = emailAddressWithDots && emailAddressWithDots[0] && emailAddressWithDots[0].charAt(0);
    const secondLetter = emailAddressWithDots && emailAddressWithDots[1] && emailAddressWithDots[1].charAt(0);
    const { openMobile, setOpenMobile } = props;
    const numberOfAlarms = alarms && alarms.length;
    const hasAlarms = numberOfAlarms > 0;
    return (React.createElement("div", { ref: sideBarRef, className: `ui vertical sidebar-mobile-menu mobile-parent-menu ${openMobile ? 'open' : 'closed'}` },
        React.createElement("div", { className: 'close-sidebar-icon', onClick: () => setOpenMobile(false) },
            React.createElement(IconClose, null)),
        React.createElement("div", { id: 'logo' },
            React.createElement(NavLink, { to: '/dashboard', onClick: (() => onClickItem('dashboard'),
                    () => setOpenMobile(false)) },
                React.createElement(LogoGreenAndWhite, null))),
        React.createElement("div", { className: 'user-profile-mobile' },
            React.createElement(Link, { to: '/settings', className: 'user-profile-sidebar-closed', onClick: (() => onClickItem('settings'),
                    () => setOpenMobile(false)) },
                React.createElement("p", { className: 'user-name' }, emailAddress ? emailAddress : ''),
                React.createElement("div", { className: 'user-image user-characters' },
                    React.createElement("h1", null,
                        firstLetter && React.createElement("span", { className: 'first' }, firstLetter),
                        secondLetter && React.createElement("span", { className: 'second' }, secondLetter))))),
        React.createElement("div", { className: 'sub-menu' },
            React.createElement("div", { className: 'menu-with-submenu' },
                React.createElement("ul", { id: 'nav' },
                    React.createElement("li", { id: 'dashboard' },
                        React.createElement(NavLink, { exact: true, to: '/dashboard', onClick: (() => onClickItem('dashboard'),
                                () => setOpenMobile(false)), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconHome, null)),
                            React.createElement("p", null, t('main.dashboard')))),
                    React.createElement("li", { id: 'alarms' },
                        React.createElement(NavLink, { to: '/alarms', onClick: (() => onClickItem('alarms'),
                                () => setOpenMobile(false)), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconAlarms, null)),
                            React.createElement("p", null,
                                t('main.alarms'),
                                hasAlarms &&
                                    React.createElement("span", { className: 'notification-alarm-number' }, numberOfAlarms)))),
                    React.createElement("li", { id: 'locations' },
                        React.createElement(NavLink, { to: '/locations', onClick: () => onClickItem('locations'), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconLocations, null)),
                            React.createElement("p", null, t('main.locations')),
                            React.createElement("div", { className: 'chevron' },
                                React.createElement(IconChevron, null))),
                        React.createElement("ul", { id: 'sub-nav-locations', className: 'content' }, locations && locations.length > 0 && locations.map((item, key) => {
                            return (React.createElement("li", { key: key, id: `locations-${item.id}` },
                                React.createElement(NavLink, { to: `/locations/${item.id}`, className: 'sub-item', onClick: (() => onClickSubItem('locations', `locations-${item.id}`),
                                        () => setOpenMobile(false)) },
                                    React.createElement("p", null,
                                        item.name,
                                        item && item.online === false && (React.createElement("span", { className: 'offline' },
                                            React.createElement(IconCloudOffline, null)))))));
                        }))),
                    React.createElement("li", { id: 'widgets' },
                        React.createElement(NavLink, { to: '/widgets', onClick: () => onClickItem('widgets'), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconWidgets, null)),
                            React.createElement("p", null, t('main.widgets')),
                            React.createElement("div", { className: 'chevron' },
                                React.createElement(IconChevron, null))),
                        React.createElement("ul", { id: 'sub-nav-widgets', className: 'content' }, widgetTabs && widgetTabs.length > 0 && widgetTabs.map((item, key) => {
                            return (React.createElement("li", { key: key, id: `widgets-${item.uuid}` },
                                React.createElement(NavLink, { to: `/widgets/${item.uuid}`, className: 'sub-item', onClick: (() => onClickSubItem('widgets', `widgets-${item.uuid}`),
                                        () => setOpenMobile(false)) },
                                    React.createElement("p", null, item.name))));
                        }))),
                    React.createElement("li", { id: 'analytics' },
                        React.createElement(NavLink, { to: '/analytics', onClick: (() => onClickItem('analytics'),
                                () => setOpenMobile(false)), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconMonitoring, null)),
                            React.createElement("p", null, t('main.analytics')))),
                    (customerAccount || serviceAccount) && (React.createElement("li", { id: 'admin' },
                        React.createElement(NavLink, { to: '/admin', onClick: () => onClickItem('admin'), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconAdmin, null)),
                            React.createElement("p", null, t('main.admin')),
                            React.createElement("div", { className: 'chevron' },
                                React.createElement(IconChevron, null))),
                        React.createElement("ul", { id: 'sub-nav-admin', className: 'content' },
                            React.createElement("li", { id: 'admin-users' },
                                React.createElement(NavLink, { to: '/admin/users', className: 'sub-item', onClick: (() => onClickSubItem('admin', 'admin-users'),
                                        () => setOpenMobile(false)) },
                                    React.createElement("p", null, t('main.users')))),
                            React.createElement("li", { id: 'admin-dealers' },
                                React.createElement(NavLink, { to: '/admin/dealers', className: 'sub-item', onClick: (() => onClickSubItem('admin', 'admin-dealers'),
                                        () => setOpenMobile(false)) },
                                    React.createElement("p", null, t('main.dealers')))),
                            React.createElement("li", { id: 'admin-subscription' },
                                React.createElement(NavLink, { to: '/admin/subscription', className: 'sub-item', onClick: (() => onClickSubItem('admin', 'admin-subscription'),
                                        () => setOpenMobile(false)) },
                                    React.createElement("p", null, t('main.subscription')))),
                            isCustomer &&
                                React.createElement("li", { id: 'admin-add-location' },
                                    React.createElement(NavLink, { to: '/admin/add-location', className: 'sub-item', onClick: (() => onClickSubItem('admin', 'admin-add-location'),
                                            () => setOpenMobile(false)) },
                                        React.createElement("p", null, t('main.addLocation'))))))),
                    React.createElement("li", { id: 'settings' },
                        React.createElement(NavLink, { to: '/settings', onClick: (() => onClickItem('settings'),
                                () => setOpenMobile(false)), className: 'parent-item' },
                            React.createElement("span", { className: 'menu-icon' },
                                React.createElement(IconSettings, null)),
                            React.createElement("p", null, t('main.settings'))))))),
        React.createElement(LogoutButton, { mobileMenu: true, setOpenMobile: setOpenMobile })));
};
export default MainMenuMobile;
