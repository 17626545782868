import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationNL from '../locales/nl-NL.json';
import translationEN from '../locales/en-GB.json';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const resources = {
    en: {
        translation: translationEN,
    },
    nl: {
        translation: translationNL,
    },
};
i18n
    .use(LngDetector)
    .use(XHR)
    .use(initReactI18next)
    .init({
    resources,
    lng: cookies.get('language') ? cookies.get('language') : 'nl',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: true,
    },
});
export default i18n;
