import React, { useState } from 'react';
import IconLogout from '!svg-react-loader?name=IconLogout!assets/svg/icon-logout.svg';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { toastrSuccess } from '@elementComponents/Toastr';
import { useDispatch } from 'react-redux';
import { isMechanicAccount, isServiceAccount } from '@utils/auth';
import { Button, Modal, ModalContent, ModalDescription } from 'semantic-ui-react';
import { getThemeClassName } from '@utils/functions';
import { StienenPopup } from '@elementComponents/Popup';
import { logoutSuccess } from 'app/login/state/slice';
import { clearCustomerSuccess, clearCustomersSuccess } from 'app/customers/state/slice';
import { clearLocationsSuccess } from 'app/locations/state/slice';
import { clearDealerInvitesSuccess } from 'app/dealer/state/slice';
import { clearEventLogSuccess } from 'app/eventLog/state/slice';
import { clearMetricsSuccess, clearProductsSuccess } from 'app/admin/subscription/state/slice';
const LogoutButton = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const mechanicAccount = isMechanicAccount();
    const serviceAccount = isServiceAccount();
    const [open, setOpen] = useState(false);
    const { mobileMenu, setOpenMobile } = props;
    const onClickLogoutOut = async () => {
        Auth.signOut()
            .then(() => {
            setOpenMobile && setOpenMobile(false);
            toastrSuccess(t('auth.logoutMessageHeader'), t('auth.logoutMessageText'));
            dispatch(logoutSuccess());
            dispatch(clearCustomersSuccess());
            dispatch(clearCustomerSuccess());
            dispatch(clearLocationsSuccess());
            dispatch(clearDealerInvitesSuccess());
            dispatch(clearEventLogSuccess());
            dispatch(clearMetricsSuccess());
            dispatch(clearProductsSuccess());
            history.push('/login');
        })
            .catch((err) => console.log(err));
    };
    const onClickDealerLogout = () => {
        setOpen(false);
        setOpenMobile && setOpenMobile(false);
        dispatch(clearCustomerSuccess());
        dispatch(clearLocationsSuccess());
        dispatch(clearDealerInvitesSuccess());
        dispatch(clearEventLogSuccess());
        dispatch(clearMetricsSuccess());
        dispatch(clearProductsSuccess());
        history.push('/select-customer');
    };
    const showModal = (mechanicAccount || serviceAccount);
    return mobileMenu ? (React.createElement("div", { className: 'mobile-logout-btns' },
        showModal &&
            React.createElement(Button, { className: 'text-btn', content: t('main.switchCustomer'), onClick: () => onClickDealerLogout() }),
        React.createElement(Button, { className: 'theme-btn', content: t('main.logout'), onClick: () => onClickLogoutOut() }))) : (React.createElement(React.Fragment, null,
        React.createElement(StienenPopup, { forceDarkTheme: true, content: showModal ? t('main.logoutOrSwitchCustomer') : t('main.logout'), trigger: React.createElement(Link, { to: '#', onClick: showModal ? () => setOpen(true) : () => onClickLogoutOut() },
                React.createElement(IconLogout, null)) }),
        React.createElement(Modal, { size: 'tiny', closeIcon: true, onClose: () => setOpen(false), onOpen: () => setOpen(true), open: open, className: `stienen-modal ${getThemeClassName()}` },
            React.createElement(ModalContent, null,
                React.createElement(ModalDescription, { className: 'text-align-center' },
                    React.createElement("p", null, t('main.logoutModalText')),
                    React.createElement(Button, { className: 'text-btn', content: t('main.switchCustomer'), onClick: () => onClickDealerLogout() }),
                    React.createElement(Button, { className: 'theme-btn', content: t('main.logout'), onClick: () => onClickLogoutOut() }))))));
};
export default LogoutButton;
