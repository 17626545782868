import React from 'react';
import { Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { TableHeaderBorder } from '@elementComponents/TableHeaderBorder';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getNamedTranslation } from '@utils/reference';
import { getDeviceById } from '@utils/devices';
import { useSelector } from 'react-redux';
const DataTable = (props) => {
    const { t } = useTranslation();
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const reference = useSelector((state) => state.reference && state.reference.device);
    const { columnNames, analytics, analyticsLoading, analyticsDatesReversed } = props;
    return (React.createElement(Table, { fixed: true, sortable: true, selectable: true, className: `stienen-table sticky-header ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
        React.createElement(TableHeader, null,
            React.createElement(TableHeaderBorder, null),
            React.createElement(TableRow, null,
                React.createElement(TableHeaderCell, null, t('analytics.dateAndTime')),
                columnNames && columnNames.length > 0 && columnNames.map((item, key) => {
                    const device = getDeviceById(item.deviceId, locations);
                    return (React.createElement(TableHeaderCell, { key: key },
                        getNamedTranslation(device && device.hardware, device && device.version, item.name, reference && reference),
                        device && device.name && React.createElement("i", null, device.name)));
                }))),
        React.createElement(TableBody, null, analyticsLoading ?
            React.createElement(TableRow, { style: { height: '60px' } },
                React.createElement(TableCell, { colSpan: 6, textAlign: 'center' },
                    React.createElement(Icon, { loading: true, name: 'spinner' })))
            : analyticsDatesReversed &&
                analyticsDatesReversed.length > 0
                ? analyticsDatesReversed.map((data, key) => {
                    return (React.createElement(TableRow, { key: key },
                        React.createElement(TableCell, null, format(parseISO(data.dateTime), 'dd-MM-yyyy / HH:mm')),
                        columnNames && columnNames.map((item, key) => {
                            const findVar = analytics.find((dataVar) => dataVar.varName === item.name).data;
                            const findData = findVar.find((dataValue) => dataValue.dateTime === data.dateTime);
                            return (React.createElement(TableCell, { key: key }, findData && findData.value !== null ? findData.value : '-'));
                        })));
                }) : (React.createElement(TableRow, { style: { height: '60px' } },
                React.createElement(TableCell, { colSpan: 6, textAlign: 'center' }, t('analytics.noDataAvailable')))))));
};
export default DataTable;
