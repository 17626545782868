import React, { useEffect, useState } from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, ModalActions, ModalContent, ModalDescription, ModalHeader, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomerAccount } from '@utils/auth';
import { HeaderBorder } from '@elementComponents/HeaderBorder';
import { createDeviceBackup } from 'app/device/state/api';
const CreateBackup = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = props;
    const [openModal, setOpenModal] = useState(false);
    const deviceBackups = useSelector((state) => state.device && state.device.backups && state.device.backups.data);
    console.log('deviceBackups=', deviceBackups);
    useEffect(() => {
        console.log('deviceId=', deviceId);
        console.log('openModal=', openModal);
        deviceId && openModal && dispatch(createDeviceBackup(deviceId));
    }, [deviceId, openModal]);
    const onCloseModal = () => {
        setOpenModal(false);
    };
    return (React.createElement(Modal, { closeIcon: true, size: 'tiny', closeOnDimmerClick: false, onClose: () => onCloseModal(), onOpen: () => setOpenModal(true), open: openModal, className: `stienen-modal ${getThemeClassName()}`, trigger: React.createElement(Button, { disabled: !isCustomerAccount(), className: 'theme-btn btn-with-svg-icon' }, t('locations.createBackup')) },
        React.createElement(ModalContent, null,
            React.createElement(HeaderBorder, null),
            React.createElement(ModalHeader, null,
                React.createElement("h2", null, t('locations.createBackup'))),
            React.createElement(ModalDescription, null,
                React.createElement("p", null, t('locations.createBackupText', {
                    date: 'date',
                    name: 'device'
                })),
                React.createElement(Segment, { inverted: getThemeClassName() === 'dark-theme', className: 'p1', textAlign: 'center' },
                    React.createElement(Icon, { loading: true, name: 'spinner', size: 'large' }),
                    React.createElement("br", null),
                    React.createElement("p", { className: 'mt1' }, t('locations.pleaseWaitAMoment'))))),
        React.createElement(ModalActions, null,
            React.createElement(Button, { className: 'theme-btn', content: t('main.cancel'), onClick: () => onCloseModal() }))));
};
export default CreateBackup;
