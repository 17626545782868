import React from 'react';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './TabContent/SortableItem';
import { Grid } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
const TabContent = (props) => {
    const { tabCurrentContent } = props;
    return (React.createElement("div", { className: 'widgets-tab-content' },
        React.createElement(SortableContext, { items: tabCurrentContent, strategy: horizontalListSortingStrategy },
            React.createElement(Grid, null,
                React.createElement(Grid.Row, null, tabCurrentContent.map((content, key) => {
                    return content && (React.createElement(Grid.Column, { key: key, width: isMobile ? 16 : content.width },
                        React.createElement(SortableItem, { handle: true, content: content })));
                }))))));
};
export default TabContent;
