import React from 'react';
import { format, parseISO } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Cookies from 'universal-cookie';
const DataChart = (props) => {
    const cookies = new Cookies();
    const { columnNames, analytics, analyticsLoading, analyticsDates } = props;
    const analyticsCategories = analyticsDates && analyticsDates.map((item) => {
        return format(parseISO(item.dateTime), 'dd-MM-yyyy / HH:mm');
    });
    const seriesArray = columnNames && columnNames.map((item) => {
        const findData = analytics && analytics.find((dataValue) => dataValue.varName === item.name);
        const currentData = findData && findData.data && findData.data.map((item2) => {
            return item2.value;
        });
        return {
            name: item.name,
            type: 'line',
            data: currentData && currentData,
            marker: {
                enabled: false
            }
        };
    });
    const lightOrDarkHex = cookies.get('theme') === 'dark' ? 'f5f5f5' : '020202';
    const options = {
        title: false,
        xAxis: [
            {
                categories: analyticsCategories,
                plotLines: [{
                        color: `#${lightOrDarkHex}`,
                        width: 1,
                        value: 13,
                        dashStyle: 'Dot',
                        zIndex: 20
                    }],
                crosshair: true
            }
        ],
        yAxis: [
            {
                title: null,
                labels: {
                    format: '{value}'
                }
            },
            {
                title: false,
                opposite: true
            }
        ],
        tooltip: {
            shared: true
        },
        series: seriesArray && seriesArray,
        plotOptions: {
            series: {
                showInLegend: true
            }
        }
    };
    return (React.createElement("div", { className: 'mt1' }, !analyticsLoading ?
        React.createElement(HighchartsReact, { highcharts: Highcharts, options: options })
        : 'loading...'));
};
export default DataChart;
