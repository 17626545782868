import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { StienenInfoPopup } from '@elementComponents/Popup';
const Component = (props) => {
    const { validateBorders, action, info, input, meta, background, placeholder, width, fluid, label, className, icon, ...rest } = props;
    const widthText = width === 1
        ? 'one'
        : width === 2
            ? 'two'
            : width === 3
                ? 'three'
                : width === 4
                    ? 'four'
                    : width === 5
                        ? 'five'
                        : width === 6
                            ? 'six'
                            : width === 7
                                ? 'seven'
                                : width === 8
                                    ? 'eight'
                                    : width === 9
                                        ? 'nine'
                                        : width === 10
                                            ? 'ten'
                                            : width === 11
                                                ? 'eleven'
                                                : width === 12
                                                    ? 'twelve'
                                                    : width === 13
                                                        ? 'thirteen'
                                                        : width === 14
                                                            ? 'fourteen'
                                                            : width === 15
                                                                ? 'fiveteen'
                                                                : width === 16
                                                                    ? 'sixteen'
                                                                    : '';
    return (React.createElement(Form.Field, { className: `
      custom-input ${className} ${background === 'content' && 'content-bg'} ${width && `${widthText} wide`}
      ${action ? 'action-input' : ''}
      ${validateBorders && meta.touched && meta.error ? 'error' : ''} 
      ${validateBorders && meta.touched && !meta.active && !meta.error ? 'correct' : ''}
      ` },
        label && React.createElement("label", null, label),
        React.createElement("div", { className: `ui ${fluid ? 'fluid' : ''} ${icon ? 'icon' : ''} input` },
            React.createElement("input", { ...input, ...rest, placeholder: placeholder }),
            icon && icon,
            info && (React.createElement("div", { className: 'input-info-icon' },
                React.createElement(StienenInfoPopup, { content: info }))),
            action && (React.createElement(Button, { className: `input-button theme-btn action-btn no-margin btn-with-svg-icon ${action.btnColor === 'positive' ? 'positive' : ''}`, onClick: action.onClick, disabled: action.btnDisabled }, action.content && action.content))),
        meta.touched && meta.error && React.createElement("span", { className: 'error' }, meta.error)));
};
export const ReduxInput = (props) => React.createElement(Field, { component: Component, ...props });
export default React.memo(ReduxInput);
