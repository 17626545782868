import api from '@state/api';
const workerEndPoint = `${process.env.WORKER_API}/workerapi/v1`;
const userEndPoint = `${process.env.USER_API}/userapi/v1`;
export const getMetrics = async (companyId) => {
    const { data } = await api.get(`${workerEndPoint}/data/metrics/${companyId}`);
    return data;
};
export const getProducts = async () => {
    const { data } = await api.get(`${userEndPoint}/products`);
    return data;
};
