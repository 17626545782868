var React = require('react');

function IconInfoCircle (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M8.5,13.5h1V8h-1V13.5z M9,6.6c0.2,0,0.3-0.1,0.4-0.2C9.6,6.3,9.6,6.1,9.6,6c0-0.2-0.1-0.3-0.2-0.4C9.3,5.4,9.2,5.3,9,5.3\n\tc-0.2,0-0.3,0.1-0.4,0.2C8.4,5.6,8.4,5.8,8.4,6c0,0.2,0.1,0.3,0.2,0.4C8.7,6.5,8.8,6.6,9,6.6z M9,18c-1.2,0-2.4-0.2-3.5-0.7\n\tc-1.1-0.5-2-1.1-2.9-1.9c-0.8-0.8-1.5-1.8-1.9-2.9C0.2,11.4,0,10.2,0,9c0-1.2,0.2-2.4,0.7-3.5c0.5-1.1,1.1-2,1.9-2.9\n\tc0.8-0.8,1.8-1.5,2.9-1.9C6.6,0.2,7.8,0,9,0c1.2,0,2.4,0.2,3.5,0.7c1.1,0.5,2,1.1,2.9,1.9c0.8,0.8,1.5,1.8,1.9,2.9\n\tC17.8,6.6,18,7.8,18,9c0,1.2-0.2,2.4-0.7,3.5c-0.5,1.1-1.1,2-1.9,2.9c-0.8,0.8-1.8,1.5-2.9,1.9C11.4,17.8,10.2,18,9,18z M9,17\n\tc2.2,0,4.1-0.8,5.7-2.3S17,11.2,17,9s-0.8-4.1-2.3-5.7S11.2,1,9,1S4.9,1.8,3.3,3.3S1,6.8,1,9s0.8,4.1,2.3,5.7S6.8,17,9,17z"}));
}

IconInfoCircle.defaultProps = {"version":"1.1","id":"Layer_1","x":"0px","y":"0px","viewBox":"0 0 18 18","style":{"enableBackground":"new 0 0 18 18"},"xmlSpace":"preserve"};

module.exports = IconInfoCircle;

IconInfoCircle.default = IconInfoCircle;
