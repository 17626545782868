import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Sidebar, SidebarPushable, Tab, TabPane } from 'semantic-ui-react';
import EditModeTabs from './EditModeSidebar/EditModeTabs';
import { reduxForm } from 'redux-form';
import EditModeContent from './EditModeSidebar/EditModeContent';
const EditModeSidebar = (props) => {
    const { t } = useTranslation();
    const { visible, setVisible, tabCurrentContent, selectedTab, selectedContent, setTabCurrentContent } = props;
    const [activeIndex, setActiveIndex] = useState(1);
    const editModeTabs = [
        { index: 0, name: t('widgets.tabs') },
        { index: 1, name: t('widgets.content') }
    ];
    const handleTabChange = (index) => {
        setActiveIndex(index);
    };
    const panes = editModeTabs.map((tab) => {
        return {
            menuItem: (React.createElement(MenuItem, { key: tab.index, active: activeIndex === tab.index, onClick: () => handleTabChange(tab.index) },
                React.createElement("h3", null, tab.name))),
            render: () => React.createElement(TabPane, { className: 'no-border' }, tab.index === 0
                ? React.createElement(EditModeTabs, { visible: visible, setVisible: setVisible })
                : tab.index === 1
                    ? React.createElement(EditModeContent, { visible: visible, setVisible: setVisible, tabCurrentContent: tabCurrentContent, selectedTab: selectedTab, selectedContent: selectedContent, setTabCurrentContent: setTabCurrentContent })
                    : `De tab van ${tab.name}`),
        };
    });
    return (React.createElement(SidebarPushable, { className: 'sidebar-widgets' },
        React.createElement(Sidebar, { direction: 'right', animation: 'overlay', visible: visible },
            React.createElement("div", { className: 'stienen-tabs no-border' },
                React.createElement("div", { className: 'header-border' }),
                React.createElement(Tab, { activeIndex: activeIndex, panes: panes })))));
};
export default reduxForm({
    form: 'EditModeSidebarForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
})(EditModeSidebar);
