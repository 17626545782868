import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import IconHome from '!svg-react-loader?name=IconHome!assets/svg/icon-home.svg';
import IconAdmin from '!svg-react-loader?name=IconAdmin!assets/svg/icon-admin.svg';
import IconWidgets from '!svg-react-loader?name=IconWidgets!assets/svg/icon-widget.svg';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import Cookies from 'universal-cookie';
import { isCustomerAccount, isServiceAccount } from '@utils/auth';
import AlarmMenuItem from './AlarmMenuItem';
import IconMonitoring from '!svg-react-loader?name=IconMonitoring!assets/svg/icon-monitoring.svg';
const MainMenu = (props) => {
    const cookies = new Cookies();
    const history = useHistory();
    const customerAccount = isCustomerAccount();
    const serviceAccount = isServiceAccount();
    const [pagePath, setPagePath] = useState('');
    const [sidebar, setSidebar] = useState(cookies.get('sidebar'));
    const { onClickSidebarBtn } = props;
    useEffect(() => {
        setPagePath(location.pathname);
        history.listen((location) => {
            setPagePath(location.pathname);
            setSidebar(cookies.get('sidebar'));
        });
    }, [history]);
    return (React.createElement("div", { id: 'menu' },
        React.createElement("ul", { className: 'parent-menu', onClick: () => onClickSidebarBtn(false) },
            React.createElement("li", null,
                React.createElement(NavLink, { exact: true, to: {
                        pathname: '/dashboard',
                        state: { prevPath: pagePath, prevSidebar: sidebar },
                    } },
                    React.createElement(IconHome, null))),
            React.createElement(AlarmMenuItem, { pagePath: pagePath, sidebar: sidebar, onClickSidebarBtn: onClickSidebarBtn })),
        React.createElement("ul", { className: 'company-menu' },
            React.createElement("li", null,
                React.createElement(NavLink, { to: {
                        pathname: '/locations',
                        state: { prevPath: pagePath, prevSidebar: sidebar },
                    }, onClick: () => onClickSidebarBtn(true) },
                    React.createElement(IconLocations, null))),
            React.createElement("li", null,
                React.createElement(NavLink, { to: {
                        pathname: '/widgets',
                        state: { prevPath: pagePath, prevSidebar: sidebar },
                    }, onClick: () => onClickSidebarBtn(false) },
                    React.createElement(IconWidgets, null))),
            React.createElement("li", null,
                React.createElement(NavLink, { to: {
                        pathname: '/analytics',
                        state: { prevPath: pagePath, prevSidebar: sidebar },
                    }, onClick: () => onClickSidebarBtn(false) },
                    React.createElement(IconMonitoring, null))),
            (customerAccount || serviceAccount) && (React.createElement("li", null,
                React.createElement(NavLink, { to: {
                        pathname: '/admin',
                        state: { prevPath: pagePath, prevSidebar: sidebar },
                    }, onClick: () => onClickSidebarBtn(true) },
                    React.createElement(IconAdmin, null)))))));
};
export default MainMenu;
