import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import slugify from 'slugify';
const CsvDownload = (props) => {
    const { t } = useTranslation();
    const [csvData, setCsvData] = useState([]);
    const { columnNames, analytics, analyticsDatesReversed, device } = props;
    useEffect(() => {
        const csvHeader = [
            t('analytics.dateAndTime'),
            ...columnNames && columnNames.map((item) => {
                return item.name;
            })
        ];
        const csvData = analyticsDatesReversed && analyticsDatesReversed.map((item) => {
            const dataObject = columnNames && columnNames.map((item2) => {
                const findVar = analytics && analytics.find((dataVar) => dataVar.name === item2.name)?.data;
                const findData = findVar && findVar.find((dataValue) => dataValue.dateTime === item.dateTime);
                return findData && findData.value !== null ? findData.value : 0;
            });
            return [
                format(parseISO(item.dateTime), 'dd-MM-yyyy / HH:mm'),
                ...dataObject
            ];
        });
        csvData && setCsvData([csvHeader, ...csvData]);
    }, [analyticsDatesReversed]);
    const deviceName = device && slugify(device.name, { lower: true });
    const fileName = `${format(new Date(), 'yyyy-MM-dd_HH-mm')}_${deviceName}.csv`;
    return (React.createElement(Button, { as: CSVLink, content: t('analytics.exportToCsv'), separator: ';', data: csvData && csvData, filename: fileName, target: '_blank', className: 'theme-btn btn-with-svg-icon no-margin' }));
};
export default CsvDownload;
