import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarPushable, Divider, Button } from 'semantic-ui-react';
import { reduxForm, reset } from 'redux-form';
import IconClose from '!svg-react-loader?name=IconClose!assets/svg/icon-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TabsList from 'app/widgets/components/EditModeSidebar/EditModeTabs/TabsList';
import TabsListItem from 'app/widgets/components/EditModeSidebar/EditModeTabs/TabsListItem';
import { storeColumnsSuccess } from 'app/analytics/state/slice';
import Dropdown from '@formComponents/Dropdown';
import { orderBy } from 'lodash/fp';
const AnalyticsEditModeSidebar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { visible, setVisible } = props;
    const [columnsItems, setColumnsItems] = useState([]);
    const columns = useSelector((state) => state && state.analytics && state.analytics.columns);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const formValues = useSelector((state) => state.form.AnalyticsEditModeSidebarForm && state.form.AnalyticsEditModeSidebarForm.values);
    const listOfDevices = [];
    locations &&
        locations.map((location) => {
            location.devices &&
                location.devices.map((device) => {
                    listOfDevices.push({
                        key: device.id,
                        deviceId: device.id,
                        deviceName: device.name,
                        locationId: location.id,
                        locationName: location.name,
                        text: `${device.name} (${location.name})`,
                        value: device.id
                    });
                });
        });
    const listOfDevicesSorted = orderBy([(device) => device && device.locationName.toLowerCase(), (device) => device && device.deviceName.toLowerCase()], ['asc', 'asc'], listOfDevices);
    useEffect(() => {
        columns && columns.length > 0 && setColumnsItems(columns);
    }, [columns, visible]);
    const onCloseSideBar = () => {
        setVisible(false);
        dispatch(reset('AnalyticsEditModeSidebarForm'));
    };
    const onSaveEditMode = () => {
        const newArray = columnsItems.map((obj, key) => {
            return {
                index: key,
                deviceId: obj.deviceId,
                uuid: obj.uuid,
                name: obj.name
            };
        });
        dispatch(storeColumnsSuccess(newArray));
        setVisible(false);
        dispatch(reset('AnalyticsEditModeSidebarForm'));
    };
    const onDeleteTab = (uuid) => {
        const newArray = columnsItems.filter((obj) => {
            return obj.uuid !== uuid;
        });
        setColumnsItems(newArray);
    };
    const dragEnded = (param) => {
        const { source, destination } = param;
        const _arr = [...columnsItems];
        const _item = _arr.splice(source.index, 1)[0];
        _arr.splice(destination.index, 0, _item);
        setColumnsItems(_arr);
    };
    console.log('formValues=', formValues);
    return (React.createElement(SidebarPushable, { className: 'sidebar-widgets' },
        React.createElement(Sidebar, { direction: 'right', animation: 'overlay', visible: visible },
            React.createElement("div", { className: 'close-sidebar-icon', onClick: () => onCloseSideBar() },
                React.createElement(IconClose, null)),
            React.createElement("div", { className: 'analytics-sidebar' },
                React.createElement("b", { className: 'label' }, t('analytics.newColumn')),
                React.createElement(Dropdown, { fluid: true, search: true, required: true, name: 'selectedDevice', placeholder: t('analytics.selectDevice'), options: listOfDevicesSorted }),
                formValues && formValues.selectedDevice &&
                    React.createElement(Dropdown, { fluid: true, search: true, required: true, name: 'selectedColumn', placeholder: t('analytics.selectColumn') }),
                React.createElement(Button, { floated: 'right', className: 'theme-btn', content: t('analytics.add') }),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(Divider, { section: true }),
                React.createElement("b", { className: 'label' }, t('analytics.edit')),
                React.createElement(DragDropContext, { onDragEnd: dragEnded },
                    React.createElement(Droppable, { droppableId: 'tabItems-wrapper' }, (provided) => (React.createElement(TabsList, { ref: provided.innerRef, ...provided.droppableProps },
                        columnsItems && columnsItems.length > 0 && columnsItems.map((item, index) => {
                            return (React.createElement(Draggable, { draggableId: `item-${item.uuid}`, index: index, key: item.uuid }, (_provided, _snapshot) => (React.createElement(TabsListItem, { visible: visible, formName: 'AnalyticsEditModeSidebarForm', ref: _provided.innerRef, dragHandleProps: _provided.dragHandleProps, ..._provided.draggableProps, snapshot: _snapshot, item: item, onDeleteTab: onDeleteTab, tabItems: columnsItems }))));
                        }),
                        provided.placeholder)))),
                React.createElement(Button, { floated: 'right', className: 'theme-btn', content: t('main.save'), onClick: () => onSaveEditMode() }),
                React.createElement(Button, { floated: 'right', className: 'text-btn', content: t('main.cancel'), onClick: () => onCloseSideBar() })))));
};
export default reduxForm({
    form: 'AnalyticsEditModeSidebarForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
})(AnalyticsEditModeSidebar);
