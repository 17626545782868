import { createSlice } from '@reduxjs/toolkit';
import { getDevice, getDeviceBackups, getDeviceMenu, getDeviceScreen, getDeviceState } from './api';
const initialState = {
    info: {},
    menu: {},
    state: {},
    screen: {},
    backups: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const device = createSlice({
    name: 'device',
    initialState,
    reducers: {
        getDeviceInfoSuccess(state, action) {
            state.info = action.payload;
        },
        getDeviceInfoFailure(state, action) {
            state.info = '';
            console.log(action.payload);
        },
        getDeviceMenuSuccess(state, action) {
            state.menu = action.payload;
        },
        getDeviceMenuFailure(state, action) {
            state.menu = '';
            console.log(action.payload);
        },
        getDeviceStateSuccess(state, action) {
            state.state = action.payload;
        },
        getDeviceStateFailure(state, action) {
            state.state = '';
            console.log(action.payload);
        },
        getDeviceScreenSuccess(state, action) {
            state.state = action.payload;
        },
        getDeviceScreenFailure(state, action) {
            state.state = '';
            console.log(action.payload);
        },
        getDeviceBackupsStart(state) {
            state.backups.loading = true;
        },
        getDeviceBackupsSuccess(state, action) {
            state.backups.data = action.payload;
            state.backups.loading = false;
            state.backups.isLoaded = true;
        },
        getDeviceBackupsFailure(state, action) {
            state.backups.loading = false;
            state.backups.isLoaded = false;
            state.backups.error = action.payload;
            console.log(action.payload);
        },
        clearDeviceSuccess(state) {
            state.info = {};
            state.state = {};
            state.screen = {};
        },
    }
});
export const { getDeviceInfoSuccess, getDeviceInfoFailure, getDeviceMenuSuccess, getDeviceMenuFailure, getDeviceStateSuccess, getDeviceStateFailure, getDeviceScreenSuccess, getDeviceScreenFailure, getDeviceBackupsStart, getDeviceBackupsSuccess, getDeviceBackupsFailure, clearDeviceSuccess, } = device.actions;
export default device.reducer;
export const fetchDevice = (deviceId) => async (dispatch) => {
    try {
        const device = await getDevice(deviceId);
        dispatch(getDeviceInfoSuccess(device));
    }
    catch (err) {
        dispatch(getDeviceInfoFailure(err && err.message));
    }
};
export const fetchDeviceMenu = (hardware, version, deviceId) => async (dispatch) => {
    try {
        const device = await getDeviceMenu(hardware, version, deviceId);
        dispatch(getDeviceMenuSuccess(device));
    }
    catch (err) {
        dispatch(getDeviceMenuFailure(err && err.message));
    }
};
export const fetchDeviceState = (deviceId) => async (dispatch) => {
    try {
        const device = await getDeviceState(deviceId);
        dispatch(getDeviceStateSuccess(device));
    }
    catch (err) {
        dispatch(getDeviceStateFailure(err && err.message));
    }
};
export const fetchDeviceScreen = (deviceId) => async (dispatch) => {
    try {
        const device = await getDeviceScreen(deviceId);
        console.log('device=', device);
    }
    catch (err) {
        dispatch(getDeviceScreenFailure(err && err.message));
    }
};
export const fetchDeviceBackups = (deviceId) => async (dispatch) => {
    try {
        dispatch(getDeviceBackupsStart());
        const device = await getDeviceBackups(deviceId);
        console.log('device.data=', device.data);
        dispatch(getDeviceBackupsSuccess(device.data));
    }
    catch (err) {
        dispatch(getDeviceBackupsFailure(err && err.message));
    }
};
