import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsTable from './components/AnalyticsTable';
const Analytics = () => {
    const { t } = useTranslation();
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.analytics')))),
        React.createElement(AnalyticsTable, null)));
};
export default Analytics;
