import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { getCookieLocale } from '@utils/locales';
import Cookies from 'universal-cookie';
const HeaderDate = () => {
    const [, setValue] = useState(new Date());
    const cookies = new Cookies();
    const dateFormat = cookies.get('dateFormat');
    const timeFormat = cookies.get('timeFormat');
    useEffect(() => {
        cookies.get('dateFormat') === undefined && cookies.set('dateFormat', 'E d MMM');
        cookies.get('timeFormat') === undefined && cookies.set('timeFormat', 'H:mm');
    }, []);
    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const removeDots = dateFormat === 'EEEE d MMM' ? '.'
        : dateFormat === 'E d MMM' ? '.'
            : dateFormat === 'd MMM' ? '.'
                : dateFormat === 'd MMM yyyy' ? '.'
                    : '';
    const fullFormat = (timeFormat === 'hide' || timeFormat === undefined) ? `${dateFormat}` : `${dateFormat} ${timeFormat}`;
    return (React.createElement("div", { className: 'date' }, dateFormat && format(new Date(), fullFormat, {
        locale: getCookieLocale(),
    }).replace(removeDots, '')));
};
export default HeaderDate;
