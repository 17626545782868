import * as React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
const Component = (props) => {
    const { required, input, background, width, meta, floatingLabel, label, options, className, ...rest } = props;
    const widthText = width === 1
        ? 'one'
        : width === 2
            ? 'two'
            : width === 3
                ? 'three'
                : width === 4
                    ? 'four'
                    : width === 5
                        ? 'five'
                        : width === 6
                            ? 'six'
                            : width === 7
                                ? 'seven'
                                : width === 8
                                    ? 'eight'
                                    : width === 9
                                        ? 'nine'
                                        : width === 10
                                            ? 'ten'
                                            : width === 11
                                                ? 'eleven'
                                                : width === 12
                                                    ? 'twelve'
                                                    : width === 13
                                                        ? 'thirteen'
                                                        : width === 14
                                                            ? 'fourteen'
                                                            : width === 15
                                                                ? 'fiveteen'
                                                                : width === 16
                                                                    ? 'sixteen'
                                                                    : '';
    return (React.createElement(Form.Field, { className: `custom-dropdown ${background === 'content' && 'content-bg'} ${width && `${widthText} wide`}` },
        label && React.createElement("label", { className: floatingLabel ? 'floating-label' : '' }, label),
        React.createElement(Dropdown, { selection: true, required: required, options: options, id: 'parent-dropdown', ...input, onFocus: (e) => input.onFocus(e), onChange: (_e, { value }) => {
                input.onChange(value);
            }, onBlur: (_e, { value }) => {
                input.onBlur(value);
            }, value: input.value, ...rest })));
};
export const ReduxDropdown = (props) => React.createElement(Field, { component: Component, ...props });
ReduxDropdown.displayName = 'ReduxDropdown';
export default React.memo(ReduxDropdown);
