import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import tinycolor from 'tinycolor2';
import { isMechanicAccount } from '@utils/auth';
const CompanyColor = () => {
    const cookies = new Cookies();
    const mechanicAccount = isMechanicAccount();
    const hex = mechanicAccount ? 'F86F00' : cookies.get('color') && cookies.get('color');
    const hexDarker = tinycolor(hex).darken(5).toHex();
    const rgb = tinycolor(hex).toRgb();
    useEffect(() => {
        const faviconUpdate = async () => {
            const favicon = document.querySelector("link[rel~='icon']");
            if (hex === '0061BB') {
                favicon.href = require('../../../../src/assets/favicon-blue.ico');
            }
            else if (hex === 'F86F00') {
                favicon.href = require('../../../../src/assets/favicon-orange.ico');
            }
            else if (hex === 'FECC00') {
                favicon.href = require('../../../../src/assets/favicon-yellow.ico');
            }
            else if (hex === 'EC1C25') {
                favicon.href = require('../../../../src/assets/favicon-red.ico');
            }
            else {
                favicon.href = require('../../../../src/assets/favicon.ico');
            }
        };
        faviconUpdate();
    }, [hex]);
    return (React.createElement(React.Fragment, null,
        React.createElement("style", { dangerouslySetInnerHTML: {
                __html: `
          .theme-btn,
          ul.parent-menu li a.active,
          ul.company-menu li a.active {
            background: #${hex}!important;
          }

          h1, h2, h3, h4, h5, h6,
          .breadcrumbs .item h1 span {
            color: #${hex}!important;
          }

          #favicon path:nth-child(2),
          #logo a svg path:nth-child(2),
          #logo a svg path:nth-child(3),
          .loading-container .logo-spinner svg path:nth-child(3) {
            fill: #${hex}!important;
          }

          .sub-menu .sub-menu-header {
            border-right: 8px solid #${hex}!important;
          }

          div.menu-with-submenu li.active a.parent-item {
            border-right: 8px solid #${hex}!important;
            background: #${hex}!important;
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},1) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }

          div.menu-with-submenu a.sub-item,
          div.menu-without-submenu a.item 
          {
            border-right: 8px solid rgba(${rgb['r']},${rgb['g']},${rgb['b']},.3)!important;
          }

          div.menu-without-submenu a.active.item,
          div.menu-with-submenu li ul li.active a.sub-item
          {
            border-right: 8px solid rgba(${rgb['r']},${rgb['g']},${rgb['b']},1)!important;
          }

          .ui.card.stienen-card .content .header-border,
          .ui.modal.stienen-modal .content .header-border,
          .stienen-tabs .header-border,
          .ui.table.stienen-table tr.header-border,
          .ui.table.stienen-table.selectable tr.header-border:hover {
            background: rgb(${rgb['r']},${rgb['g']},${rgb['b']})!important;
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 0) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 15%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 50%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 85%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 0) 100%)!important;
          }

          .ui.selection.dropdown .ui.label {
            background: #${hex}!important;
          }

          .ui.selection.dropdown .ui.label:hover {
            background: #${hexDarker}!important;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #${hex} !important;
          }

          .theme-btn:hover {
            background: #${hexDarker} !important;
          }
        `,
            } })));
};
export default CompanyColor;
