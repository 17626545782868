import React from 'react';
import IconChicken from '!svg-react-loader?name=IconChicken!assets/svg/icon-chicken.svg';
import IconWeight from '!svg-react-loader?name=IconWeight!assets/svg/icon-weight.svg';
import IconWater from '!svg-react-loader?name=IconWater!assets/svg/icon-water.svg';
import IconFood from '!svg-react-loader?name=IconFood!assets/svg/icon-food.svg';
import IconClimate from '!svg-react-loader?name=IconClimate!assets/svg/icon-climate.svg';
export const getWidgetIcon = (name) => {
    return name === 'chicken' ? React.createElement(IconChicken, null)
        : name === 'weight' ? React.createElement(IconWeight, null)
            : name === 'water' ? React.createElement(IconWater, null)
                : name === 'food' ? React.createElement(IconFood, null)
                    : name === 'climate' ? React.createElement(IconClimate, null)
                        : null;
};
