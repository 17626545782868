import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { reset } from 'redux-form';
import IconClose from '!svg-react-loader?name=IconClose!assets/svg/icon-close.svg';
import SortableItem from '../TabContent/SortableItem';
const EditModeContent = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setVisible, selectedContent, setTabCurrentContent } = props;
    const widgets = useSelector((state) => state && state.widgets && state.widgets.widgets);
    const onCloseSideBar = () => {
        setVisible(false);
        setTabCurrentContent(selectedContent.content);
        dispatch(reset('EditModeSidebarForm'));
    };
    const onSaveEditMode = () => {
        setVisible(false);
        dispatch(reset('EditModeSidebarForm'));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'close-sidebar-icon', onClick: () => onCloseSideBar() },
            React.createElement(IconClose, null)),
        React.createElement("b", { className: 'label' }, t('widgets.addWidget')),
        React.createElement("div", { className: 'add-widget-container' },
            React.createElement(Grid, null,
                React.createElement(Grid.Row, { columns: 1 }, widgets && widgets.length > 0 && widgets.map((item, key) => {
                    return (React.createElement(Grid.Column, { key: key },
                        React.createElement(SortableItem, { handle: true, content: item })));
                })))),
        React.createElement(Button, { floated: 'right', className: 'theme-btn', content: t('main.save'), onClick: () => onSaveEditMode() }),
        React.createElement(Button, { floated: 'right', className: 'text-btn', content: t('main.cancel'), onClick: () => onCloseSideBar() })));
};
export default EditModeContent;
