import * as React from 'react';
const style = {
    color: '#db2828',
};
const Label = (props) => {
    const { content, error, touched, className } = props;
    if (!content && error === true) {
        return null;
    }
    if (error && touched) {
        return (React.createElement("label", { className: className },
            content,
            "\u00A0",
            React.createElement("span", { style: style }, error)));
    }
    return content ? React.createElement("label", { className: className }, content) : null;
};
export default Label;
