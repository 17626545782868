import React, { useEffect } from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { TableHeaderBorder } from '@elementComponents/TableHeaderBorder';
import DatePicker from '@formComponents/DatePicker';
import { change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'lodash/fp';
import { format, parseISO, startOfDay, sub } from 'date-fns';
import { dateWithoutTimezone } from '@utils/dates';
import { useParams } from 'react-router-dom';
import { getDeviceById } from '@utils/devices';
import { fetchAlarmHistoryRange } from 'app/alarms/state/slice';
import { getAlarmTranslation } from '@utils/reference';
import { fetchAlarmReference } from 'app/reference/state/slice';
import { getCookieLocale } from '@utils/locales';
const AlarmsHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = useParams();
    const alarmsList = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.data);
    const alarmsListLoading = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.loading);
    const formValues = useSelector((state) => state.form.AlarmsHistoryForm && state.form.AlarmsHistoryForm.values);
    const alarmReference = useSelector((state) => state.reference && state.reference.alarm);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const device = getDeviceById(deviceId, locations);
    const alarmsListReversed = alarmsList && alarmsList.length > 0 && reverse(alarmsList);
    useEffect(() => {
        const numberOfDays = 14;
        const currentDate = new Date();
        const endDate = format(currentDate, 'dd-MM-yyyy');
        const endDateISO = dateWithoutTimezone(currentDate);
        const startDate = format(sub(currentDate, { days: numberOfDays }), 'dd-MM-yyyy');
        const startDateISO = dateWithoutTimezone(startOfDay(sub(currentDate, { days: numberOfDays })));
        startDate && (dispatch(change('AlarmsHistoryForm', 'StartDate', startDate)),
            dispatch(change('AlarmsHistoryForm', 'StartDateISO', startDateISO)));
        endDate && (dispatch(change('AlarmsHistoryForm', 'EndDate', endDate)),
            dispatch(change('AlarmsHistoryForm', 'EndDateISO', endDateISO)));
        dispatch(change('AlarmsHistoryForm', 'DateRange', `${startDate && startDate} t/m ${endDate && endDate}`));
    }, []);
    useEffect(() => {
        device && device.id && formValues && formValues.StartDateISO && formValues.EndDateISO && (dispatch(fetchAlarmHistoryRange(device.id, formValues.StartDateISO, formValues.EndDateISO)));
    }, [formValues && formValues.RangeIsSelected === true]);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, device && device.name))),
        React.createElement("div", { className: 'justify-content-space-between' },
            React.createElement("div", { className: 'justify-content-start' },
                React.createElement("div", { className: 'mr1' },
                    React.createElement(DatePicker, { selectsRange: true, showToday: false, maxDate: new Date(), label: t('alarms.dateRange'), name: 'DateRange', formName: 'AlarmsHistoryForm' })))),
        React.createElement(Table, { selectable: true, className: `stienen-table sticky-header ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
            React.createElement(TableHeader, null,
                React.createElement(TableHeaderBorder, null),
                React.createElement(TableRow, null,
                    React.createElement(TableHeaderCell, null),
                    React.createElement(TableHeaderCell, null, t('alarms.date')),
                    React.createElement(TableHeaderCell, null, t('alarms.code')),
                    React.createElement(TableHeaderCell, null, t('alarms.control')))),
            React.createElement(TableBody, null, alarmsListLoading ?
                React.createElement(TableRow, { style: { height: '60px' } },
                    React.createElement(TableCell, { colSpan: 6, textAlign: 'center' },
                        React.createElement(Icon, { loading: true, name: 'spinner' })))
                : alarmsListReversed && alarmsListReversed.length > 0
                    ? alarmsListReversed.map((alarm, key) => {
                        const hardware = alarm.hardware;
                        const version = alarm.version;
                        const language = getCookieLocale().code;
                        const findReference = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language);
                        if (hardware && version && language && !findReference) {
                            dispatch(fetchAlarmReference(hardware, version, 'nl'));
                        }
                        return (React.createElement(TableRow, { key: key, className: 'show-btn-on-hover', style: { height: '60px' } },
                            React.createElement(TableCell, null,
                                "#",
                                key + 1),
                            React.createElement(TableCell, null, format(parseISO(alarm.timeStamp), 'dd-MM-yyyy / HH:mm')),
                            React.createElement(TableCell, null, getAlarmTranslation(hardware, version, 'nl', alarm.alarmCode, alarmReference)),
                            React.createElement(TableCell, null, alarm.controlText)));
                    }) : (React.createElement(TableRow, { style: { height: '60px' } },
                    React.createElement(TableCell, { colSpan: 6, textAlign: 'center' }, t('alarms.noAlarmsAvailable'))))))));
};
export default reduxForm({
    form: 'AlarmsHistoryForm',
})(AlarmsHistory);
