import { isMechanicAccount } from '@utils/auth';
import tinycolor from 'tinycolor2';
import Cookies from 'universal-cookie';
export const tabsArray = [
    { index: 0, uuid: '590d8cfe-6b7d-430c-8635-35d5db3dd6ad', color: 'f44336', name: 'Kippenstal 1', friendlyName: 'kippenstal-1' },
    { index: 1, uuid: '955de133-dd71-4df7-951d-62d829a951bd', color: 'f44336', name: 'Kippenstal 2', friendlyName: 'kippenstal-2' },
    { index: 2, uuid: '955de133-dd71-4df7-953d-62d829a951bd', color: 'f44336', name: 'Kippenstal 3', friendlyName: 'kippenstal-3' },
    { index: 3, uuid: '8f309524-2dde-4f82-ae68-fe9c659c502e', color: '36bcf4', name: 'Varkensstal 1', friendlyName: 'varkensstal-1' },
    { index: 4, uuid: '8f309524-2d1e-4f82-ae68-fe91659c502e', color: '36bcf4', name: 'Varkensstal 2', friendlyName: 'varkensstal-2' },
];
export const tabContentArray = [
    { id: '1', icon: 'chicken', type: 'list', width: '4', title: 'Dieren' },
    { id: '2', icon: 'weight', type: 'lineChart', width: '8', title: 'Gewicht' },
    { id: '3', icon: 'climate', type: 'list', width: '4', title: 'Klimaat' },
    { id: '4', icon: 'food', type: 'list', width: '4', title: 'Voedsel' },
    { id: '5', icon: 'water', type: 'lineChart', width: '8', title: 'Water' }
];
export const widgetsArray = [
    { id: '1000', icon: 'chicken', type: 'clock', width: '4', title: '' },
    { id: '1001', icon: 'chicken', type: 'temp', width: '4', title: '' },
    { id: '1002', icon: 'chicken', type: 'count', width: '4', title: '' },
    { id: '1003', icon: 'pork', type: 'clock', width: '4', title: '' },
    { id: '1004', icon: 'pork', type: 'temp', width: '4', title: '' },
    { id: '1005', icon: 'pork', type: 'count', width: '4', title: '' },
    { id: '1006', icon: 'owl', type: 'clock', width: '4', title: '' },
    { id: '1007', icon: 'owl', type: 'temp', width: '4', title: '' },
    { id: '1008', icon: 'owl', type: 'count', width: '4', title: '' },
    { id: '1009', icon: 'chicken', type: 'clock', width: '4', title: '' },
    { id: '1010', icon: 'chicken', type: 'temp', width: '4', title: '' },
    { id: '1011', icon: 'chicken', type: 'count', width: '4', title: '' },
    { id: '1012', icon: 'pork', type: 'clock', width: '4', title: '' },
    { id: '1013', icon: 'pork', type: 'temp', width: '4', title: '' },
    { id: '1014', icon: 'pork', type: 'count', width: '4', title: '' },
    { id: '1015', icon: 'owl', type: 'clock', width: '4', title: '' },
    { id: '1016', icon: 'owl', type: 'temp', width: '4', title: '' },
    { id: '1017', icon: 'owl', type: 'count', width: '4', title: '' }
];
export const listAnimals = [
    { label: 'Number of animals', value: 44073, type: '' },
    { label: 'Total weight', value: 83651, type: 'kg' },
    { label: 'Average weight', value: 1898, type: 'gr' },
    { label: 'Maximum weight', value: 2013, type: 'gr' },
    { label: 'Minimum weight', value: 1723, type: 'gr' },
    { label: 'Growth per day', value: 73, type: 'gr' },
];
export const listClimate = [
    { label: 'House temperature', value: 22.3, type: '°C' },
    { label: 'Relative humidity', value: 74, type: '%' },
    { label: 'CO₂', value: 2311, type: 'ppm' },
    { label: 'Ventilation', value: 40, type: '%' },
    { label: 'Heating', value: 80, type: '%' },
    { label: 'Cooling', value: 'off', type: '' },
];
export const listFeed = [
    { label: 'Food per animal', value: 120, type: 'gr/d' },
    { label: 'Water per animal', value: 88, type: 'ml/d' },
    { label: 'Silo 1', value: 80, type: '%', value2: 2400, type2: 'kg' },
    { label: 'Silo 2', value: 40, type: '%', value2: 1200, type2: 'kg' },
    { label: 'Silo 3', value: 50, type: '%', value2: 1500, type2: 'kg' }
];
export const waterLineChartOptions = () => {
    const cookies = new Cookies();
    const mechanicAccount = isMechanicAccount();
    const hex = mechanicAccount ? 'F86F00' : cookies.get('color') && cookies.get('color');
    const hexDarker = tinycolor(hex).darken(10).toHex();
    const lightOrDarkHex = cookies.get('theme') === 'dark' ? 'f5f5f5' : '020202';
    return {
        xAxis: {
            categories: [
                '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'
            ],
            plotLines: [{
                    color: `#${lightOrDarkHex}`,
                    width: 1,
                    value: 13,
                    dashStyle: 'Dot',
                    zIndex: 20
                }]
        },
        yAxisLabel: '{value} ml',
        colors: [`#${hex}`, `#${lightOrDarkHex}`, `#${hexDarker}`],
        seriesData: [
            {
                name: 'Vandaag',
                type: 'column',
                borderWidth: 0,
                yAxis: 1,
                data: [
                    2.7, 3.7, 4, 8, 5.7, 6, 7, 7, 7, 6, 6, 5, 4.2
                ],
                tooltip: {
                    valueSuffix: ' ml'
                }
            },
            {
                name: 'Gemiddeld',
                type: 'line',
                data: [
                    3.8, 4.6, 4, 5.8, 5, 7, 5, 2, 6, 2, 4, 4, 8, 7, 8, 5, 7
                ],
                tooltip: {
                    valueSuffix: ' ml'
                },
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Vorige maand',
                type: 'spline',
                data: [
                    3, 4, 5, 9, 4, 4, 6, 4, 6, 3, 4, 5, 9, 7, 7, 6, 7
                ],
                tooltip: {
                    valueSuffix: ' ml'
                },
                marker: {
                    enabled: false
                }
            }
        ]
    };
};
export const weightLineChartOptions = () => {
    const cookies = new Cookies();
    const mechanicAccount = isMechanicAccount();
    const hex = mechanicAccount ? 'F86F00' : cookies.get('color') && cookies.get('color');
    const lightOrDarkHex = cookies.get('theme') === 'dark' ? 'f5f5f5' : '020202';
    return {
        xAxis: {
            categories: [
                '0', '5', '10', '15', '20', '25', '30', '35', '40', '45'
            ],
            plotLines: [{
                    color: `#${lightOrDarkHex}`,
                    width: 1,
                    value: 16,
                    dashStyle: 'Dot',
                    zIndex: 20
                }]
        },
        yAxisLabel: '{value} gr',
        colors: [`#${lightOrDarkHex}`, `#${hex}`],
        seriesData: [
            {
                name: 'Huidige gewicht',
                data: [
                    [.2],
                    [.4],
                    [.6],
                    [.8],
                    [1],
                    [1.2],
                    [1.4],
                    [1.6],
                    [1.8],
                    [1.9],
                    [2.0],
                    [2.1],
                    [2.2],
                    [2.3],
                    [2.4],
                    [2.5],
                    [2.6]
                ],
                zIndex: 1,
                marker: {
                    enabled: false
                }
            }, {
                name: 'Standaard +6%',
                data: [
                    [0.2, 0.3],
                    [0.4, 0.5],
                    [0.6, 0.7],
                    [0.8, 1],
                    [1, 1.2],
                    [1.2, 1.4],
                    [1.4, 1.6],
                    [1.6, 1.8],
                    [1.8, 2],
                    [1.9, 2.2],
                    [2.0, 2.3],
                    [2.1, 2.4],
                    [2.2, 2.5],
                    [2.3, 2.6],
                    [2.4, 2.7],
                    [2.5, 2.8],
                    [2.6, 3],
                    [2.7, 3.1],
                    [2.8, 3.2],
                    [2.9, 3.3],
                    [3.0, 3.4],
                    [3.0, 3.4],
                    [3.0, 3.4]
                ],
                type: 'arearange',
                lineWidth: 0,
                linkedTo: ':previous',
                zIndex: 0,
                tooltip: {
                    valueSuffix: ' gr'
                },
                marker: {
                    enabled: false
                }
            }
        ]
    };
};
