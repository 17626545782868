import React, { forwardRef, useEffect } from 'react';
import IconDragIndicator from '!svg-react-loader?name=IconDragIndicator!assets/svg/icon-drag-indicator.svg';
import IconDelete from '!svg-react-loader?name=IconDelete!assets/svg/icon-delete.svg';
import Input from '@formComponents/Input';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import ColorPicker from '@formComponents/ColorPicker';
import { getDeviceById } from '@utils/devices';
const TabsListItem = forwardRef(({ dragHandleProps, snapshot, formName, visible, tabItems, onDeleteTab, item, ...props }, ref) => {
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const device = getDeviceById(item.deviceId, locations);
    useEffect(() => {
        formName === 'EditModeSidebarForm' && (dispatch(change(formName, item && item.uuid, item.name)),
            dispatch(change(formName, `${item && item.uuid}-color`, item.color)));
    }, [visible]);
    return (React.createElement("li", { ref: ref, ...props, className: 'drag-and-drop-list-item ' + (snapshot.isDragging ? 'hovering' : ''), style: {
            ...props.style
        } },
        React.createElement("span", { className: 'drag-indicator', ...dragHandleProps },
            React.createElement(IconDragIndicator, null)),
        formName === 'EditModeSidebarForm'
            ? React.createElement(Input, { fluid: true, name: item && item.uuid })
            : formName === 'AnalyticsEditModeSidebarForm' ? React.createElement("span", { className: 'text' },
                item.name,
                " ",
                React.createElement("i", null,
                    "(",
                    device && device.name,
                    ")"))
                : React.createElement("span", { className: 'text' }, item.name),
        tabItems && tabItems.length > 1 &&
            React.createElement("span", { className: 'delete', onClick: () => onDeleteTab(item.uuid) },
                React.createElement(IconDelete, null)),
        formName === 'EditModeSidebarForm' &&
            React.createElement("span", { className: 'color-block' },
                React.createElement(ColorPicker, { name: `${item && item.uuid}-color` }))));
});
TabsListItem.displayName = 'TabsListItem';
export default TabsListItem;
