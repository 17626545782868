import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { fetchAnalytics, storeColumnsSuccess } from '../state/slice';
import { useTranslation } from 'react-i18next';
import DatePicker from '@formComponents/DatePicker';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { reverse } from 'lodash/fp';
import { startOfDay } from 'date-fns';
import { dateWithoutTimezone } from '@utils/dates';
import Dropdown from '@formComponents/Dropdown';
import DataTable from './AnalyticsTable/DataTable';
import { Button } from 'semantic-ui-react';
import DataChart from './AnalyticsTable/DataChart';
import CsvDownload from './AnalyticsTable/CsvDownload';
import { Link } from 'react-router-dom';
import AnalyticsEditModeSidebar from './AnalyticsTable/AnalyticsEditModeSidebar';
const AnalyticsTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = React.useState(false);
    const [view, setView] = React.useState('table');
    const columns = useSelector((state) => state && state.analytics && state.analytics.columns);
    const analytics = useSelector((state) => state.analytics && state.analytics && state.analytics.list && state.analytics.list.data);
    const analyticsLoading = useSelector((state) => state.analytics && state.analytics.list && state.analytics.list.loading);
    const formValues = useSelector((state) => state.form.AnalyticsTableForm && state.form.AnalyticsTableForm.values);
    const analyticsDates = analytics && analytics[0] && analytics[0].data;
    const analyticsDatesReversed = analyticsDates && analyticsDates.length > 0 && reverse(analyticsDates);
    const columnNames = [
        {
            index: 0,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 0,
            name: 'R1_Tmom'
        }, {
            index: 1,
            deviceId: 'ed228825-635f-48b8-8081-0f145b3c1f43',
            uuid: 1,
            name: 'R1_Tmom'
        }, {
            index: 2,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 2,
            name: 'R0_VENT'
        }, {
            index: 3,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 3,
            name: 'MIX_CAPDIER'
        }, {
            index: 4,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 5,
            name: 'MIX_BEDRIJFT'
        }
    ];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    useEffect(() => {
        setView(urlParams.get('view') ? urlParams.get('view') : null);
    }, [queryString]);
    useEffect(() => {
        dispatch(storeColumnsSuccess(columnNames));
    }, []);
    const IntervalOptions = () => {
        return [
            { key: '-4', value: '-4', text: t('intervals.millisecond') },
            { key: '-3', value: '-3', text: t('intervals.seconds') },
            { key: '-2', value: '-2', text: t('intervals.minutes') },
            { key: '-1', value: '-1', text: t('intervals.hours') },
            { key: '0', value: '0', text: t('intervals.days') },
            { key: '1', value: '1', text: t('intervals.weeks') },
            { key: '2', value: '2', text: t('intervals.months') },
            { key: '3', value: '3', text: t('intervals.quarters') },
            { key: '4', value: '4', text: t('intervals.years') },
            { key: '5', value: '5', text: t('intervals.minutes10') }
        ];
    };
    const numberOfDays = view === 'chart' ? 2 : 2;
    const intervalNumber = view === 'chart' ? '-1' : '-1';
    const currentDate = new Date();
    const endDate = format(currentDate, 'dd-MM-yyyy');
    const endDateISO = dateWithoutTimezone(currentDate);
    const startDate = format(sub(currentDate, { days: numberOfDays }), 'dd-MM-yyyy');
    const startDateISO = dateWithoutTimezone(startOfDay(sub(currentDate, { days: numberOfDays })));
    useEffect(() => {
        startDate && (dispatch(change('AnalyticsTableForm', 'StartDate', startDate)),
            dispatch(change('AnalyticsTableForm', 'StartDateISO', startDateISO)));
        endDate && (dispatch(change('AnalyticsTableForm', 'EndDate', endDate)),
            dispatch(change('AnalyticsTableForm', 'EndDateISO', endDateISO)));
        dispatch(change('AnalyticsTableForm', 'Interval', intervalNumber));
        dispatch(change('AnalyticsTableForm', 'DateRange', `${startDate && startDate} t/m ${endDate && endDate}`));
    }, [view]);
    useEffect(() => {
        const columnsApi = columns && columns.map((item) => {
            return {
                'deviceId': item.deviceId,
                'varName': item.name
            };
        });
        columnsApi && formValues && formValues.StartDateISO && formValues.EndDateISO && formValues.Interval && (dispatch(fetchAnalytics(columnsApi, formValues.StartDateISO, formValues.EndDateISO, formValues.Interval)));
    }, [formValues && formValues.RangeIsSelected === true, formValues && formValues.Interval]);
    const onClickEditTable = () => {
        setVisible(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'justify-content-space-between' },
            React.createElement("div", { className: 'justify-content-start' },
                React.createElement("div", { className: 'mr1' },
                    React.createElement(DatePicker, { selectsRange: true, showToday: false, maxDate: new Date(), label: t('analytics.dateRange'), name: 'DateRange', formName: 'AnalyticsTableForm' })),
                React.createElement("div", { className: 'mr1' },
                    React.createElement(Dropdown, { selection: true, name: 'Interval', label: t('analytics.interval'), options: IntervalOptions(), onChange: (_e, v) => (dispatch(change('AnalyticsTableForm', 'Interval', v))) }))),
            React.createElement("div", { className: 'align-items-end' },
                view === 'table' || view === null ?
                    React.createElement(Button, { as: Link, content: t('analytics.viewAsChart'), to: '/analytics?view=chart', onClick: () => setView('chart'), className: 'theme-btn btn-with-svg-icon no-margin' })
                    : view === 'chart' &&
                        React.createElement(Button, { as: Link, content: t('analytics.viewAsTable'), to: '/analytics?view=table', onClick: () => setView('table'), className: 'theme-btn btn-with-svg-icon no-margin' }),
                React.createElement(CsvDownload, { columnNames: columns, analytics: analytics, analyticsDatesReversed: analyticsDatesReversed }),
                React.createElement(Button, { className: 'theme-btn', content: t('widgets.editMode'), onClick: () => onClickEditTable() }))),
        analyticsDatesReversed && analyticsDatesReversed.length && React.createElement("p", { className: 'mt1' }, t('analytics.recordsText', { records: analyticsDatesReversed.length })),
        view === 'table' || view === null ?
            React.createElement(DataTable, { columnNames: columns, analytics: analytics, analyticsLoading: analyticsLoading, analyticsDatesReversed: analyticsDatesReversed })
            : view === 'chart' &&
                React.createElement(DataChart, { columnNames: columns, analytics: analytics, analyticsLoading: analyticsLoading, analyticsDates: analyticsDates }),
        React.createElement(AnalyticsEditModeSidebar, { visible: visible, setVisible: setVisible })));
};
export default reduxForm({
    form: 'AnalyticsTableForm',
})(AnalyticsTable);
