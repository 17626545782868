import { createSlice } from '@reduxjs/toolkit';
import { getLocations, getLocationsByMechanicId } from './api';
const initialState = {
    add: {
        step1: {},
        step2: {},
        step3: {},
    },
    list: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    },
    status: {}
};
const locations = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        getLocationsStart(state) {
            state.list.loading = true;
        },
        getLocationsSuccess(state, action) {
            state.list.data = action.payload;
            state.list.loading = false;
            state.list.isLoaded = true;
        },
        getLocationsFailure(state, action) {
            state.list.loading = false;
            state.list.isLoaded = false;
            state.list.error = action.payload;
            console.log(action.payload);
        },
        clearLocationsSuccess(state) {
            state.list.data = [],
                state.list.loading = false,
                state.list.isLoaded = false,
                state.list.error = '';
        }
    }
});
export const { getLocationsStart, getLocationsSuccess, getLocationsFailure, clearLocationsSuccess } = locations.actions;
export default locations.reducer;
export const fetchLocations = (customerId) => async (dispatch) => {
    try {
        dispatch(getLocationsStart());
        const locationsList = await getLocations(customerId);
        dispatch(getLocationsSuccess(locationsList));
    }
    catch (err) {
        dispatch(getLocationsFailure(err && err.message));
    }
};
export const fetchLocationsByMechanicId = (mechanicId) => async (dispatch) => {
    try {
        dispatch(getLocationsStart());
        const locationsList = await getLocationsByMechanicId(mechanicId);
        dispatch(getLocationsSuccess(locationsList));
    }
    catch (err) {
        dispatch(getLocationsFailure(err && err.message));
    }
};
