import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: {},
    success: false
};
const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        storeUserSuccess(state, action) {
            state.user = action.payload;
            state.success = true;
        },
        storeTempUserSuccess(state, action) {
            state.user = action.payload;
            state.success = false;
        },
        logoutSuccess(state) {
            state.user = {};
            state.success = false;
        }
    }
});
export const { storeUserSuccess, storeTempUserSuccess, logoutSuccess } = auth.actions;
export default auth.reducer;
