import React, { useEffect, useState } from 'react';
import Logo from './Header/Logo';
import Icons from './Header/Icons';
import HeaderDate from './Header/Date';
import BreadCrumbs from './Header/BreadCrumbs';
import { getSidebarWidth } from '@utils/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from '@utils/customer';
import { getUserId, isCustomerAccount, isDealerAccount, isMechanicAccount, isServiceAccount } from '@utils/auth';
import { fetchCustomer, fetchCustomers } from 'app/customers/state/slice';
import { fetchLocations } from 'app/locations/state/slice';
import { fetchUser } from 'app/admin/users/state/slice';
import { fetchProducts } from 'app/admin/subscription/state/slice';
import HeaderAlarms from './Header/Alarms';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MainMenuMobile from './Sidebar/MenuMobile';
import IconMenuMobile from '!svg-react-loader?name=IconMenuMobile!assets/svg/icon-menu.svg';
import { fetchDealerInvites } from 'app/dealer/state/slice';
const Header = (props) => {
    const dispatch = useDispatch();
    const customerId = getCustomerId();
    const userId = getUserId();
    const serviceAccount = isServiceAccount();
    const mechanicAccount = isMechanicAccount();
    const customerAccount = isCustomerAccount();
    const dealerAccount = isDealerAccount();
    const history = useHistory();
    const [openMobile, setOpenMobile] = useState(false);
    const auth = useSelector((state) => state.auth && state.auth);
    const currentCustomer = useSelector((state) => state.customers && state.customers.current);
    const customersIsLoaded = useSelector((state) => state.customers && state.customers.list && state.customers.list.isLoaded);
    const locationsIsLoaded = useSelector((state) => state.locations && state.locations.list && state.locations.list.isLoaded);
    useEffect(() => {
        auth && auth.success && customerId && dispatch(fetchProducts());
        auth && auth.success && userId && dispatch(fetchUser(userId));
        auth && auth.success && customerId && dispatch(fetchDealerInvites(customerId));
    }, [userId, customerId]);
    useEffect(() => {
        auth && auth.success && dealerAccount && (history.push('/dealer'));
    }, [auth, dealerAccount]);
    useEffect(() => {
        auth && auth.success && customerId && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(customerId));
    }, [auth, customerId, currentCustomer]);
    useEffect(() => {
        auth && auth.success && customerAccount && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(userId));
    }, [auth, customerAccount, userId]);
    useEffect(() => {
        auth && auth.success && !customerId && serviceAccount && !mechanicAccount && customersIsLoaded !== true && dispatch(fetchCustomers());
    }, [customerId, serviceAccount, mechanicAccount]);
    useEffect(() => {
        auth && auth.success && userId && locationsIsLoaded !== true && customerAccount && currentCustomer && currentCustomer.id && dispatch(fetchLocations(userId));
    }, [userId, locationsIsLoaded, currentCustomer, customerAccount]);
    return (React.createElement(React.Fragment, null,
        currentCustomer && currentCustomer.id && React.createElement(HeaderAlarms, null),
        isMobile && React.createElement(MainMenuMobile, { openMobile: openMobile, setOpenMobile: setOpenMobile }),
        React.createElement("div", { id: 'header', className: isMobile ? 'header-mobile' : '', style: {
                width: isMobile ? '100vw' : auth && !auth.success ? '100vw' : `calc(100vw - ${getSidebarWidth()}px)`,
            } },
            React.createElement("div", { className: 'header-column-left' },
                auth && auth.success && isMobile && (React.createElement("div", { className: 'menu-mobile-icon', onClick: openMobile
                        ? () => setOpenMobile(false)
                        : () => setOpenMobile(true) },
                    React.createElement(IconMenuMobile, null))),
                auth && auth.success && !isMobile && React.createElement(BreadCrumbs, null)),
            React.createElement("div", { className: 'header-column-right' },
                auth && auth.success && (React.createElement(React.Fragment, null,
                    !isMobile && React.createElement(HeaderDate, null),
                    React.createElement(Icons, { onClickSidebarBtn: props.onClickSidebarBtn }))),
                React.createElement(Logo, null)))));
};
export default Header;
