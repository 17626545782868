import React from 'react';
import { Popup } from 'semantic-ui-react';
import IconInfo from '!svg-react-loader?name=IconInfo!assets/svg/icon-info-circle.svg';
import { getThemeClassName } from '@utils/functions';
export const StienenPopup = (data) => {
    return (React.createElement(Popup, { position: 'top center', inverted: data.forceDarkTheme || getThemeClassName() === 'dark-theme', content: data.content, trigger: data.trigger }));
};
export const StienenInfoPopup = (content) => {
    return (React.createElement(Popup, { position: 'top center', inverted: getThemeClassName() === 'dark-theme', content: content, trigger: React.createElement("div", { className: 'form-checkbox-info' },
            React.createElement(IconInfo, null)) }));
};
