import React from 'react';
import { Message, MessageHeader } from 'semantic-ui-react';
export const StienenMessage = (props) => {
    const { header, description, svgIcon, type, className } = props;
    return (React.createElement(Message, { info: type === 'info', warning: type === 'warning', error: type === 'error', success: type === 'success', icon: svgIcon ? true : false, className: className },
        svgIcon && React.createElement("div", { className: 'svg-icon' }, svgIcon),
        React.createElement("div", null,
            React.createElement(MessageHeader, null, header),
            React.createElement("p", null, description))));
};
