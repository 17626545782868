import { isCustomerAccount } from '@utils/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const SubMenuAdmin = () => {
    const { t } = useTranslation();
    const isCustomer = isCustomerAccount();
    return (React.createElement("div", { className: 'sub-menu' },
        React.createElement("div", { className: 'sub-menu-header' }, t('main.admin')),
        React.createElement("div", { className: 'menu-without-submenu' },
            React.createElement(NavLink, { className: 'item', to: '/admin/users' },
                React.createElement("p", null, t('main.users'))),
            React.createElement(NavLink, { className: 'item', to: '/admin/dealers' },
                React.createElement("p", null, t('main.dealers'))),
            React.createElement(NavLink, { className: 'item', to: '/admin/subscription' },
                React.createElement("p", null, t('main.subscription'))),
            isCustomer &&
                React.createElement(NavLink, { className: 'item', to: '/admin/add-location' },
                    React.createElement("p", null, t('main.addLocation'))))));
};
export default SubMenuAdmin;
