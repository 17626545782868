import api from '@state/api';
const endPoint = `${process.env.USER_API}/userapi/v1`;
export const getCustomers = async () => {
    const { data } = await api.get(`${endPoint}/customers`);
    return data;
};
export const getCustomer = async (customerId) => {
    const { data } = await api.get(`${endPoint}/customers/${customerId}`);
    return data;
};
export const getCustomersByMechanicId = async (mechanicId) => {
    const { data } = await api.get(`${endPoint}/mechanics/${mechanicId}/customers`);
    return data;
};
export const updateServiceAccess = async (customerId, access) => {
    const { data } = await api.post(`${endPoint}/customers/${customerId}/serviceaccess`, access);
    return data;
};
