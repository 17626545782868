import React, { lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import { getSidebarWidth } from '@utils/sidebar';
import Header from './layout/components/Header';
import SubHeader from './layout/components/SubHeader';
import Sidebar from './layout/components/Sidebar';
import Device from './locations/Device';
import AlarmsActive from './alarms/Container';
import AlarmsHistory from './locations/components/AlarmsHistory';
import Widgets from './widgets/Container';
import WidgetsRedirect from './widgets/Redirect';
import Analytics from './analytics/Container';
import { isMobile } from 'react-device-detect';
import AdminRedirect from './admin/Container';
import Backups from './locations/components/Backups/Backups';
const Login = lazy(() => import('./login/Container'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));
const VerifyCode = lazy(() => import('./login/VerifyCode'));
const AdminUsers = lazy(() => import('./admin/users/Container'));
const AdminDealers = lazy(() => import('./admin/dealers/Container'));
const AdminSubscription = lazy(() => import('./admin/subscription/Container'));
const AdminAddLocation = lazy(() => import('./admin/addLocation/Container'));
const Dashboard = lazy(() => import('./dashboard/Container'));
const DealerAccount = lazy(() => import('./dealer/Container'));
const SelectCustomer = lazy(() => import('./customers/Container'));
const Settings = lazy(() => import('./settings/Container'));
const LocationsContainer = lazy(() => import('./locations/Container'));
const Location = lazy(() => import('./locations/Location'));
const EventLog = lazy(() => import('./eventLog/Container'));
const Container = () => {
    const cookies = new Cookies();
    const history = useHistory();
    const [sidebar, setSidebar] = useState(cookies.get('sidebar'));
    const auth = useSelector((state) => state.auth && state.auth);
    !cookies.get('color') && cookies.set('color', '008072');
    !cookies.get('language') && cookies.set('language', 'nl');
    if (cookies.get('sidebar') === undefined) {
        cookies.set('sidebar', 'closed', { path: '/' });
    }
    if (cookies.get('theme') === undefined) {
        cookies.set('theme', 'dark', { path: '/' });
    }
    const ProtectedRoute = ({ isAllowed, ...props }) => isAllowed ? (React.createElement(Route, { ...props })) : (React.createElement(Redirect, { push: true, to: {
            pathname: '/login',
            state: { from: `${location.pathname}${location.search}` },
        } }));
    useEffect(() => {
        const pathname = location.pathname;
        pathname === '/' && auth.success
            ? history.push('/dashboard')
            : pathname === '/' && !auth.success
                && history.push('/login');
    }, []);
    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const pathArrayWithoutEmpty = pathArray.filter(function (el) {
            return el !== '';
        });
        const tabs = ['locations', 'admin', 'custom-screens', 'alarms'];
        const atLeastOneTab = tabs.some((i) => pathArrayWithoutEmpty.includes(i));
        atLeastOneTab && performance.navigation.type !== 1 && setSidebar('open');
        atLeastOneTab && performance.navigation.type !== 1 && onClickSidebarBtn(true);
        history.listen((location) => {
            const prevPath = location.state && location.state.prevPath;
            const prevSidebar = location.state && location.state.prevSidebar;
            const currentPath = location && location.pathname;
            const pathArray = location.pathname.split('/');
            const pathArrayWithoutEmpty = pathArray.filter(function (el) {
                return el !== '';
            });
            const atLeastOneTab = tabs.some((i) => pathArrayWithoutEmpty.includes(i));
            atLeastOneTab && prevPath !== currentPath && prevSidebar === 'open'
                ? (setSidebar('open'),
                    cookies.set('sidebar', 'open', { path: '/' }),
                    document.getElementById('sidebar') && document.getElementById('sidebar').classList.remove('sidebar-closed'))
                : atLeastOneTab && prevPath === currentPath && prevSidebar === 'open'
                    ? (setSidebar('closed'),
                        cookies.set('sidebar', 'closed', { path: '/' }),
                        document.getElementById('sidebar') && document.getElementById('sidebar').classList.add('sidebar-closed'))
                    : atLeastOneTab && prevPath === currentPath && prevSidebar === 'closed'
                        ? (setSidebar('open'),
                            cookies.set('sidebar', 'open', { path: '/' }),
                            document.getElementById('sidebar') && document.getElementById('sidebar').classList.remove('sidebar-closed'))
                        : !atLeastOneTab &&
                            (setSidebar('closed'),
                                cookies.set('sidebar', 'closed', { path: '/' }),
                                document.getElementById('sidebar') && document.getElementById('sidebar').classList.add('sidebar-closed'));
        });
    }, [auth, history]);
    const onClickSidebarBtn = (forceOpen) => {
        if (forceOpen && sidebar === 'open') {
            cookies.set('sidebar', 'closed', { path: '/' });
            setSidebar('closed');
            document.getElementById('sidebar') && document.getElementById('sidebar').classList.add('sidebar-closed');
        }
        else if (forceOpen && sidebar === 'closed') {
            cookies.set('sidebar', 'open', { path: '/' });
            setSidebar('open');
            document.getElementById('sidebar') && document.getElementById('sidebar').classList.remove('sidebar-closed');
        }
        else if (!forceOpen) {
            cookies.set('sidebar', 'closed', { path: '/' });
            setSidebar('closed');
            document.getElementById('sidebar') && document.getElementById('sidebar').classList.remove('sidebar-closed');
        }
    };
    const checkDeviceTab = () => {
        if (location.pathname) {
            const containsRemoteAlready = location.pathname.includes('/remote');
            containsRemoteAlready
                ? history.push(`${location.pathname}`)
                : history.push(`${location.pathname}/remote`);
        }
    };
    return (React.createElement("div", { className: `body ${cookies.get('theme')}-theme ${isMobile ? 'body-mobile' : ''}` },
        auth && auth.success && !isMobile && React.createElement(Sidebar, { sidebar: sidebar, onClickSidebarBtn: onClickSidebarBtn }),
        React.createElement("div", { id: 'content', className: isMobile ? 'content-mobile' : '', style: {
                width: isMobile ? '100vw' : auth && !auth.success ? '100vw' : `calc(100vw - ${getSidebarWidth()}px)`,
                marginLeft: isMobile ? '0px' : auth && !auth.success ? '0px' : `${getSidebarWidth()}px`,
            } },
            React.createElement(Header, { onClickSidebarBtn: onClickSidebarBtn }),
            React.createElement(SubHeader, null),
            React.createElement("div", { className: `content-inner ${isMobile ? 'content-inner-mobile' : ''}`, style: {
                    padding: isMobile ? '0px' : auth && !auth.success ? '0px' : '21px 42px',
                } },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: '/login', component: Login }),
                    React.createElement(Route, { exact: true, path: '/forget-password', component: ForgetPassword }),
                    React.createElement(Route, { exact: true, path: '/change-password', component: ChangePassword }),
                    React.createElement(Route, { exact: true, path: '/verify-code', component: VerifyCode }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/select-customer', component: SelectCustomer }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/dealer', component: DealerAccount }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/dashboard', component: Dashboard }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/alarms', component: AlarmsActive }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/settings', component: Settings }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/admin', component: AdminRedirect }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/admin/users', component: AdminUsers }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/admin/dealers', component: AdminDealers }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/admin/subscription', component: AdminSubscription }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/admin/add-location', component: AdminAddLocation }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/widgets', component: WidgetsRedirect }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/widgets/:tabUuid', component: Widgets }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/analytics', component: Analytics }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations', component: LocationsContainer }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId', component: Location }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId/:deviceId', component: () => checkDeviceTab() }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId/:deviceId/logs/events', component: EventLog }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId/:deviceId/logs/alarms', component: AlarmsHistory }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId/:deviceId/backups', component: Backups }),
                    React.createElement(ProtectedRoute, { isAllowed: auth.success, exact: true, path: '/locations/:locationId/:deviceId/:tabType', component: Device }))))));
};
export default Container;
