import api from '@state/api';
const endPoint = `${process.env.USER_API}/userapi/v1`;
export const getDealerInvites = async (customerId) => {
    const { data } = await api.get(`${endPoint}/dealerinvites/${customerId}`);
    return data;
};
export const updateDealer = async (customerId, dealer) => {
    const { data } = await api.put(`${endPoint}/dealerinvites/${customerId}`, dealer);
    return data;
};
export const inviteDealer = async (customerId, dealer) => {
    console.log('inviteDealer');
    console.log('dealer=', dealer);
    const { data } = await api.post(`${endPoint}/dealerinvites/${customerId}`, dealer);
    console.log('data=', data);
    return data;
};
export const deleteDealer = async (customerId, dealer) => {
    const { data } = await api.delete(`${endPoint}/dealerinvites/${customerId}`, { data: dealer });
    return data;
};
export const getMechanicsByDealerId = async (dealerId) => {
    const { data } = await api.get(`${endPoint}/mechanics/${dealerId}`);
    return data;
};
export const inviteMechanic = async (dealerId, mechanic) => {
    const { data } = await api.post(`${endPoint}/mechanics/${dealerId}`, mechanic);
    return data;
};
export const deleteMechanic = async (dealerId, mechanic) => {
    const { data } = await api.delete(`${endPoint}/mechanics/${dealerId}`, { data: mechanic });
    return data;
};
