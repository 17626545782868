import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    tabs: [],
    content: [],
    widgets: []
};
const widgets = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        storeTabsSuccess(state, action) {
            state.tabs = action.payload;
        },
        storeNewTabSuccess(state, action) {
            const oldArray = state && state.tabs;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.tabs = newArray;
        },
        storeTabsContentSuccess(state, action) {
            state.content = action.payload;
        },
        storeWidgetsSuccess(state, action) {
            state.widgets = action.payload;
        },
    }
});
export const { storeTabsSuccess, storeNewTabSuccess, storeTabsContentSuccess, storeWidgetsSuccess } = widgets.actions;
export default widgets.reducer;
