export const getAlarmTranslation = (hardware, version, language, alarmCode, alarmReference) => {
    const findReference = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language);
    const referenceList = findReference && findReference.data;
    const newAlarmCode = alarmCode >> 8;
    const findText = referenceList && referenceList.find((ref) => ref.Label === newAlarmCode.toString());
    return findText ? findText.Text : alarmCode;
};
export const getNamedTranslation = (hardware, version, name, namedReference) => {
    const findReference = namedReference && namedReference.find((ref) => ref.hardware === hardware && ref.version === version);
    const referenceList = findReference && findReference.data;
    const findText = referenceList && referenceList.find((ref) => ref.Name === name);
    return findText ? findText.Name : name;
};
