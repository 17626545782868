import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import IconUser from '!svg-react-loader?name=IconUser!assets/svg/icon-user.svg';
import IconDealer from '!svg-react-loader?name=IconDealer!assets/svg/icon-dealer.svg';
import IconEuro from '!svg-react-loader?name=IconEuro!assets/svg/icon-euro.svg';
import IconAdd from '!svg-react-loader?name=IconAdd!assets/svg/icon-add.svg';
import { getNumberOfDevices } from '@utils/customer';
import { isCustomerAccount } from '@utils/auth';
const AdminRedirect = () => {
    const { t } = useTranslation();
    const numberOfDevices = getNumberOfDevices();
    const isCustomer = isCustomerAccount();
    const userInvites = useSelector((state) => state && state.customers && state.customers.current && state.customers.current.userInvites);
    const dealerInvites = useSelector((state) => state && state.dealers && state.dealers.invites && state.dealers.invites.data);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.admin')))),
        React.createElement(Grid, { stackable: true },
            React.createElement(GridRow, { columns: 3 },
                React.createElement(GridColumn, { className: 'mb2' },
                    React.createElement(Link, { to: '/admin/users' },
                        React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'header-border' }),
                                React.createElement("div", { className: 'justify-content-center align-items-center location-card pt2 pb1' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' },
                                            React.createElement(IconUser, null)),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, t('main.users')),
                                            React.createElement("br", null),
                                            React.createElement("i", null,
                                                userInvites && userInvites.length,
                                                " ",
                                                t('main.users'))))))))),
                React.createElement(GridColumn, { className: 'mb2' },
                    React.createElement(Link, { to: '/admin/dealers' },
                        React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'header-border' }),
                                React.createElement("div", { className: 'justify-content-center align-items-center location-card pt2 pb1' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' },
                                            React.createElement(IconDealer, null)),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, t('main.dealers')),
                                            React.createElement("br", null),
                                            React.createElement("i", null,
                                                dealerInvites && dealerInvites.length,
                                                " ",
                                                t('main.dealers'))))))))),
                React.createElement(GridColumn, { className: 'mb2' },
                    React.createElement(Link, { to: '/admin/subscription' },
                        React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'header-border' }),
                                React.createElement("div", { className: 'justify-content-center align-items-center location-card pt2 pb1' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' },
                                            React.createElement(IconEuro, null)),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, t('main.subscription')),
                                            React.createElement("br", null),
                                            React.createElement("i", null,
                                                numberOfDevices && numberOfDevices,
                                                " ",
                                                t('users.devices'))))))))),
                isCustomer &&
                    React.createElement(GridColumn, { className: 'mb2' },
                        React.createElement(Link, { to: '/admin/add-location' },
                            React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                                React.createElement(CardContent, null,
                                    React.createElement("div", { className: 'header-border' }),
                                    React.createElement("div", { className: 'justify-content-center align-items-center location-card pt2 pb1' },
                                        React.createElement("div", null,
                                            React.createElement("div", { className: 'justify-content-center' },
                                                React.createElement(IconAdd, null)),
                                            React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                                React.createElement("b", null, t('main.addLocation')),
                                                React.createElement("br", null),
                                                React.createElement("i", null, t('main.addLocationText')))))))))))));
};
export default AdminRedirect;
