import { createSlice } from '@reduxjs/toolkit';
import { getEventLog } from './api';
const initialState = {
    list: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const eventLog = createSlice({
    name: 'eventLog',
    initialState,
    reducers: {
        getEventLogStart(state) {
            state.list.loading = true;
        },
        getEventLogSuccess(state, action) {
            state.list.data = action.payload;
            state.list.loading = false;
            state.list.isLoaded = true;
        },
        getEventLogFailure(state, action) {
            state.list.loading = false;
            state.list.isLoaded = false;
            state.list.error = action.payload;
            console.log(action.payload);
        },
        clearEventLogSuccess(state) {
            state.list.data = [],
                state.list.loading = false,
                state.list.isLoaded = false,
                state.list.error = '';
        }
    }
});
export const { getEventLogStart, getEventLogSuccess, getEventLogFailure, clearEventLogSuccess } = eventLog.actions;
export default eventLog.reducer;
export const fetchEventLog = (deviceId) => async (dispatch) => {
    try {
        dispatch(getEventLogStart());
        const eventLogList = await getEventLog(deviceId);
        dispatch(getEventLogSuccess(eventLogList));
    }
    catch (err) {
        dispatch(getEventLogFailure(err && err.message));
    }
};
