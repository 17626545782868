import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TabPane } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
const TabLogs = (props) => {
    const { t } = useTranslation();
    const { device } = props;
    return (React.createElement(TabPane, null, device &&
        React.createElement(React.Fragment, null,
            React.createElement(Button, { as: Link, to: `/locations/${device.gatewayId}/${device.id}/logs/events`, className: 'theme-btn', content: t('devices.events') }),
            React.createElement(Button, { as: Link, to: `/locations/${device.gatewayId}/${device.id}/logs/alarms`, className: 'theme-btn', content: t('devices.alarms') }))));
};
export default TabLogs;
