import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { getCookieLocale } from '@utils/locales';
import AllAlarms from './components/AllAlarms';
const AlarmsActive = () => {
    const { t } = useTranslation();
    const lastUpdate = useSelector((state) => state.alarms && state.alarms && state.alarms.active && state.alarms.active.lastUpdate);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.alarms'))),
            React.createElement("div", null, lastUpdate &&
                React.createElement("p", null,
                    t('alarms.lastUpdate'),
                    ": \u00A0",
                    React.createElement("span", { className: 'text-transform-capitalize' }, format(parseISO(lastUpdate), 'EEEEEE d MMM HH:mm', {
                        locale: getCookieLocale(),
                    }).replace('.', ''))))),
        React.createElement(AllAlarms, null)));
};
export default AlarmsActive;
