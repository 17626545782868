import axios from 'axios';
import { getAuthHeader } from '@utils/auth';
const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});
api.interceptors.request.use(async (config) => {
    config.headers.Authorization = await getAuthHeader();
    return config;
});
export default api;
