import React from 'react';
import { toast } from 'react-semantic-toasts';
import IconInfoCircle from '!svg-react-loader?name=IconInfoCircle!assets/svg/icon-info-circle.svg';
import IconExclamationCircle from '!svg-react-loader?name=IconExclamationCircle!assets/svg/icon-exclamation-circle.svg';
import IconErrorCircle from '!svg-react-loader?name=IconErrorCircle!assets/svg/icon-cancel-circle.svg';
import IconCheckCircle from '!svg-react-loader?name=IconCheckCircle!assets/svg/icon-check-circle.svg';
import IconAlarm from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
export const toastrSuccess = (title, description) => {
    const options = {
        title,
        description,
        type: 'success',
        icon: React.createElement(IconCheckCircle, null),
        time: 4000
    };
    return toast(options);
};
export const toastrError = (title, description) => {
    const options = {
        title,
        description,
        type: 'error',
        icon: React.createElement(IconErrorCircle, null),
        time: 4000
    };
    return toast(options);
};
export const toastrWarning = (title, description) => {
    const options = {
        title,
        description,
        type: 'warning',
        icon: React.createElement(IconExclamationCircle, null),
        time: 4000
    };
    return toast(options);
};
export const toastrInfo = (title, description) => {
    const options = {
        title,
        description,
        type: 'info',
        icon: React.createElement(IconInfoCircle, null),
        time: 4000
    };
    return toast(options);
};
export const toastrAlarm = (title, description) => {
    const options = {
        title,
        description,
        type: 'error',
        icon: React.createElement(IconAlarm, null),
        time: 4000
    };
    return toast(options);
};
