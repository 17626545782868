import * as React from 'react';
import { mapErrorProps } from './SemanticField';
import { Field } from 'redux-form';
import { SketchPicker } from 'react-color';
import { FormField, Popup } from 'semantic-ui-react';
const Component = (props) => {
    const { input, meta, required, label, ...rest } = props;
    const { error } = mapErrorProps(meta, label);
    const value = input.value || '008072';
    const extraProps = { className: 'colorpicker__sketch' };
    return (React.createElement(FormField, { required: required, error: error, className: 'colorpicker' },
        React.createElement(Popup, { inverted: true, on: 'click', trigger: React.createElement(Swatch, { color: value }) },
            React.createElement(SketchPicker, { ...extraProps, color: value, onChange: color => input.onChange(color.hex.replace('#', '')), ...rest }))));
};
export const ReduxColorPicker = props => React.createElement(Field, { component: Component, ...props });
ReduxColorPicker.displayName = 'ReduxColorPicker';
export const Swatch = ({ color, ...rest }) => (React.createElement("div", { className: 'color-picker' },
    React.createElement("div", { className: 'swatch', ...rest },
        React.createElement("div", { className: 'color', style: { backgroundColor: `#${color}` } }))));
export default React.memo(ReduxColorPicker);
