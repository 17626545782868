import * as React from 'react';
import Label from './Label';
export const mapErrorProps = (meta, label) => {
    const mappedLabel = typeof label === 'string' ? React.createElement(Label, { content: label, ...meta }) : label;
    const error = meta.touched && !!meta.error;
    return {
        label: mappedLabel,
        error,
    };
};
